import { RefreshOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import SearchInputForm from 'components/ActionMenu/modules/GlobalSearch/SearchInputForm';
import { GroupFilter } from 'components/Filters';
import CreateReportButton from 'components/ScheduledReports/CreateReportButton';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import useUIContext from 'context/UIContext';
import { Formik } from 'formik';
import { ScheduledReportType } from 'hooks/reports/useScheduledReport';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GlobalSearchFormSchema from 'schemas/GlobalSearch';

const AssetStatusFilters = (props: any) => {
  const { t } = useTranslation();
  const { direction } = useUIContext();

  const children = [
    <Grid item xs={3} key='asset-status-filters-search'>
      <Formik
        validateOnMount={true}
        initialValues={{
          search: '',
        }}
        validationSchema={GlobalSearchFormSchema(t)}
        validateOnChange={false}
        validateOnBlur={false}
        onReset={() => {
          props.setSearchTerm('');
        }}
        onSubmit={(values: any, { setSubmitting }: any) => {
          props.setSearchTerm(values.search);
          setSubmitting(false);
        }}>
        {(props: any) => (
          <SearchInputForm
            {...props}
            isLoading={props.isLoading}
            setSearchTerm={props.setSearchTerm}
            placeholder={t('reports.status.search_placeholder')}
          />
        )}
      </Formik>
    </Grid>,
    <Grid item xs={3} key='asset-status-filters-group'>
      <GroupFilter {...props} multiple={true} size='small' limitTags={0} />
    </Grid>,
    <Grid item xs={6} key='asset-status-filters-report'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'rtl' === direction ? 'start' : 'end',
        }}>
        <CreateReportButton type={ScheduledReportType.ASSET_STATUS} />
        <props.exportComponent
          filters={props.filters}
          assetType={props.assetType}
        />
        <Tooltip arrow={true} title={t('reload_data')}>
          <IconButton size='small' onClick={() => props.refetch()}>
            <RefreshOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    </Grid>,
  ];

  return (
    <Grid container spacing={2} sx={{ mb: 2, ...props.gridSx }}>
      {direction === 'rtl' ? children.reverse() : children}
    </Grid>
  );
};

const AssetStatusToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <AssetStatusFilters {...props} />
    </WithUrlFilters>
  );
};

export default AssetStatusToolbarWithUrlFilters;
