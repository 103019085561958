import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import useUIContext, { DIR } from 'context/UIContext';
import React from 'react';

const CardComponent = ({
  Icon,
  Image,
  title,
  description,
  button,
  onClick,
  imgBackground,
  className,
  topIcon,
}: any) => {
  const { direction } = useUIContext();
  const CardIcon = ({ Icon, isRTL = false }: any) => {
    return (
      <Typography
        sx={{
          fontSize: 12,
          position: 'absolute',
          ...(isRTL
            ? {
                left: '1rem',
              }
            : {
                right: '1rem',
              }),
        }}
        gutterBottom>
        <Icon sx={{ fontSize: 36, color: '#00a8ff' }} />
      </Typography>
    );
  };
  const CardImage = ({
    Image,
    imgBackground = 'transparent',
    isRTL = false,
  }: any) => {
    return (
      <div
        dir={direction}
        style={{
          position: 'absolute',
          bottom: 10,
          ...(isRTL
            ? {
                left: 10,
              }
            : {
                right: 10,
              }),
          backgroundColor: imgBackground,
          borderRadius: 25,
          padding: 5,
        }}>
        <Avatar src={Image} />
      </div>
    );
  };

  return (
    <Card sx={{ minWidth: '100%' }} className={className}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          {topIcon && topIcon}
          {Icon ? (
            <CardIcon isRTL={'rtl' === direction} Icon={Icon} />
          ) : Image ? (
            <CardImage
              isRTL={'rtl' === direction}
              Image={Image}
              imgBackground={imgBackground}
            />
          ) : (
            ''
          )}
          <Typography variant='h6' component='div'>
            {title}
          </Typography>
          <Typography
            sx={{
              mb: 1.5,
              fontSize: '.9rem',
              pr: 'rtl' === direction ? 0 : '50px',
              pl: 'rtl' === direction ? '50px' : 0,
              minHeight: 86,
              maxHeight: 86,
              height: 86,
              overflow: 'hidden',
              display: '-webkit-box',
              webkitLineClamp: 4,
              lineClamp: 4,
              webkitBoxOrient: 'vertical',
            }}
            color='text.secondary'>
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size='small'
            endIcon={
              DIR.RTL === direction ? <ChevronLeftIcon /> : <ChevronRightIcon />
            }>
            {button}
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default CardComponent;
