import { Box, FormControl, MenuItem, Select } from '@mui/material';
import useUIContext from 'context/UIContext';
import { useLocalStorage } from 'hooks';
import moment from 'moment';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { languageOptions } from 'views/Settings/Profile';

export const LanguageSelector = ({}: any) => {
  const { i18n } = useTranslation();
  const { setDirection } = useUIContext();
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage(
    'language',
    'en'
  );

  const handleChange = (event: any) => {
    i18n.changeLanguage(event.target.value);
    setSelectedLanguage(event.target.value);
    document.documentElement.lang = event.target.value;
    moment.locale(event.target.value);
    if (['ar'].includes(event.target.value)) {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        marginBottom: 2,
      }}>
      <FormControl>
        <Select
          id='lang'
          value={selectedLanguage}
          onChange={handleChange}
          size='small'
          renderValue={value => (
            <ReactCountryFlag
              countryCode={
                languageOptions.find(lang => lang.value === value)?.flagIso ??
                ''
              }
            />
          )}
          sx={{
            width: '100%',
            '& .MuiSelect-select': {
              padding: '0.2rem 0.5rem',
              fontSize: '1.1rem',
            },
          }}>
          {languageOptions.map((lang: any) => (
            <MenuItem
              key={lang.value}
              value={lang.value}
              sx={{ fontSize: '.85rem' }}>
              <ReactCountryFlag
                style={{ marginRight: '.3rem' }}
                countryCode={lang.flagIso}
              />
              {lang.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
