import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import DriverFilter from 'components/Filters/DriverFilter';
import GenericFilterDumb from 'components/Filters/GenericFilterDumb';
import CreateReportButton from 'components/ScheduledReports/CreateReportButton';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import { useAssetsFilterWithSearch } from 'hooks/filters/useFilters';
import { ScheduledReportType } from 'hooks/reports/useScheduledReport';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateFilter, GroupFilter } from '../../../components/Filters';
// eslint-disable-next-line import/named
// eslint-disable-next-line import/named

const TripFilters = (props: any) => {
  const { t } = useTranslation();

  const handleShowAllTrips = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.updateFilters({
      ...props.filters,
      showAllTrips: event.target.checked,
    });
  };

  return (
    <>
      <Grid container spacing={2} sx={{ py: 0, mt: 0, pl: 1 }}>
        <Grid item xs={2}>
          <Tooltip
            title={t('reports.show_all_trips_tooltip')}
            arrow
            placement='bottom'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.filters?.showAllTrips}
                    size='small'
                    color='secondary'
                    onChange={handleShowAllTrips}
                  />
                }
                label={
                  <Typography sx={{ fontSize: '.8rem', ml: 1 }}>
                    {t('reports.show_all_trips')}
                  </Typography>
                }
              />
            </FormGroup>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ py: 0.5, mt: 0 }}>
        <Grid item xs={2}>
          <DateFilter {...props} sx={{ mr: 1 }} />
        </Grid>
        <Grid item xs={3}>
          <GenericFilterDumb
            {...props}
            multiple={true}
            size='small'
            limitTags={10}
            filterName='asset'
            label={t('Assets')}
            keyProperty='assetId'
            labelProperty='name'
            useHookForData={useAssetsFilterWithSearch}
            presetFilters={{ assetTypes: [props.assetType] }}
          />
        </Grid>
        <Grid item xs={3}>
          <GroupFilter {...props} sx={{ ml: 1 }} multiple={true} />
        </Grid>
        <Grid item xs={3}>
          {props.assetType === 'vehicle' && (
            <DriverFilter {...props} sx={{ ml: 1 }} multiple={false} />
          )}
        </Grid>
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
          <CreateReportButton
            overwriteToMultiple
            type={ScheduledReportType.TRIP}
          />
          <props.exportComponent filters={props.filters} />
        </Grid>
      </Grid>
    </>
  );
};

const TripsToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <TripFilters {...props} />
    </WithUrlFilters>
  );
};

export default TripsToolbarWithUrlFilters;
