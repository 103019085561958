import { useLocalStorage } from 'hooks';
import i18next from 'i18next';
import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';

type InputProps = {
  setFieldValue: Function;
  ref?: React.RefObject<any>;
};

export const CaptchaField = (props: InputProps) => {
  const { setFieldValue } = props;
  const [selectedLanguage] = useLocalStorage('language', 'en');
  const [language, setLanguage] = React.useState(selectedLanguage);

  i18next.on('languageChanged', function (lng) {
    setLanguage(lng);
    props.ref?.current.reset();
  });

  if (process.env.ENV === 'test') return <div data-testid='captcha-field' />;
  return (
    <>
      <h1>{process.env.ENV}</h1>
      <ReCAPTCHA
        key={language}
        ref={props.ref}
        hl={language}
        onChange={value => setFieldValue(value)}
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY!}
        theme='dark'
        size='normal'
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      />
    </>
  );
};

export default CaptchaField;
