import React, { useEffect, useMemo, useRef } from 'react';
import {
  Modal,
  Box,
  Grid,
  Skeleton,
  Button,
  IconButton,
  Slider,
  Checkbox,
  Typography,
  FormControlLabel,
  Tooltip,
  Stack,
} from '@mui/material';
import { useAllTripReports } from 'hooks/reports/useTrips';
import { Virtuoso } from 'react-virtuoso';
import AltMap from 'components/Map/AltMap';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import moment from 'moment';
import { useLocalStorage } from 'hooks';
import TripPointImage from 'assets/trip-point.svg';
import TripEndImage from 'assets/trip-end.svg';
import { lineString, point } from '@turf/helpers';
import bboxPolygon from '@turf/bbox-polygon';
import {
  Close,
  FirstPageRounded,
  PauseRounded,
  PlayArrowRounded,
} from '@mui/icons-material';
import {
  DevicePoint,
  LAYERS,
  LineStringLayer,
  LineStringSource,
  Point,
  PointSource,
  SOURCES,
} from 'components/Map';
import bbox from '@turf/bbox';
import buffer from '@turf/buffer';
import area from '@turf/area';
import { length } from '@turf/length';
import { along } from '@turf/along';
import center from '@turf/center';
import bearing from '@turf/bearing';
import TripEventsSource from 'components/Map/constants/sources/TripEventsSource';
import TripEventsLayer from 'components/Map/constants/layers/TripEventsLayer';
import { getDirectionFromDeg } from '../../../../common-web/utils/index';
import { useTranslation } from 'react-i18next';
import TripEventIndicator from 'assets/trip-event-indicator.svg';
import TripPanicIndicator from 'assets/trip-panic-indicator.svg';
import './override.scss';
import { useInfiniteDirectionData } from 'hooks/useMapbox';
import SingleAssetLocationSource from 'components/Map/constants/sources/SingleAssetLocationSource';

interface InputProps {
  tripId: any;
  open: boolean;
  onClose?: () => void;
  canMapMatch?: boolean;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '70%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const ListItem = styled.div<any>(
  ({ styleObject }: any) => css`
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 4px;
    padding-right: 4px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    cursor: pointer;
    ${styleObject && css(styleObject)};
    &:hover {
      background-color: rgba(255, 255, 255, 0.08);
    }
  `
);

const renderReportTypeIndicator = (reportType: string) => {
  switch (reportType) {
    case 'speeding_start':
    case 'harsh_acceleration':
    case 'harsh_brake':
    case 'harsh_turn':
    case 'antenna_disconnected':
    case 'gps_jamming_start':
    case 'lockout_engaged':
      return (
        <div>
          <img src={TripEventIndicator} width='10px' height='10px' />
        </div>
      );
    case 'panic_event':
    case 'panic_start':
    case 'accident_detected':
      return (
        <div>
          <img src={TripPanicIndicator} width='10px' height='10px' />
        </div>
      );
    default:
      return '';
  }
};

const playbackSpeeds = [1, 10, 25, 50, 100];

const UPDATES_PER_SECOND = 10;

const tripPointImage: any = {
  id: 'trip-point',
  src: TripPointImage,
  type: 'svg',
};
const tripEndImage: any = {
  id: 'trip-playback-trip-end',
  src: TripEndImage,
  type: 'svg',
};

const vehicleTripMapMatchedLayer: any = LineStringLayer({
  id: LAYERS.TRIP_LINE_MAP_MATCHED,
  source: SOURCES.TRIP_LINE_MAP_MATCHED,
  size: 3,
});
const tripEventsLayer: any = TripEventsLayer({
  id: LAYERS.TRIP_EVENTS_POINTS,
  source: SOURCES.TRIP_EVENTS_POINTS,
});
const tripDetailPointLayer: any = DevicePoint({
  id: LAYERS.ASSET_POINTS,
  source: SOURCES.ASSET_LOCATIONS,
});
const tripEndPointLayer: any = Point({
  id: LAYERS.TRIP_END_POINT,
  source: SOURCES.TRIP_END_POINT,
  icon: 'trip-playback-trip-end',
  size: 0.325,
  anchor: 'bottom-left',
});
const tripEventIndicatorImage: any = {
  id: 'trip-event-indicator-image',
  src: TripEventIndicator,
  type: 'svg',
};
const tripPanicIndicatorImage: any = {
  id: 'trip-panic-indicator-image',
  src: TripPanicIndicator,
  type: 'svg',
};

type ReportToSort = {
  timestamp: string;
  originalTimestamp: string;
  position: { lat: number; lon: number };
  reportId: string;
  reportType: string;
};
const removeDuplicatesAndSortByTimestamp = (
  reports: ReportToSort[]
): ReportToSort[] => {
  const uniqueReportsMap = new Map<string, ReportToSort>();

  reports.forEach(report => {
    const uniqueKey = `${report.originalTimestamp}_${report.position.lat}_${report.position.lon}_${report.reportId}_${report.reportType}`;
    if (!uniqueReportsMap.has(uniqueKey)) {
      uniqueReportsMap.set(uniqueKey, report);
    }
  });

  return Array.from(uniqueReportsMap.values()).sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );
};

export const TripPlayback = (props: InputProps) => {
  const { open, tripId, onClose } = props;
  const { data, isLoading } = useAllTripReports(tripId, open) as any;
  const [storedMapStyle, _] = useLocalStorage('mapStyle', '');
  const [playbackSpeed, setPlaybackSpeed] = React.useState(0);
  const [play, setPlay] = React.useState(false);
  const [playbackProgress, setPlaybackProgress] = React.useState(0);
  const [highlightedReport, setHighlightedReport] = React.useState<any>({
    index: 0,
    highlightFromSlider: true,
  });
  const virtuoso = React.useRef<any>(null);
  const [lineStringSource, setLineStringSource] = React.useState<any>();
  const [mapMatchedlineStringSource, setMapMatchedLineStringSource] =
    React.useState<any>();
  const [tripEventsSource, setTripEventsSource] = React.useState<any>();
  const [tripEndPointSource, setTripEndPointSource] = React.useState<any>();
  const [detailTripPointSource, setDetailTripPointSource] =
    React.useState<any>();
  const [assetPointSource, setAssetPointSource] = React.useState<any>();
  const [locationBbox, setLocationBbox] = React.useState<any>(null);
  const [mapCenter, setCenter] = React.useState<any>(null);
  const [shouldFollow, setShouldFollow] = React.useState(false);
  const [flyTo, setFlyTo] = React.useState<any>();
  const [avgSpeed, setAvgSpeed] = React.useState<string>('0');
  const [avgHeading, setAvgHeading] = React.useState<number>(0);
  const { t } = useTranslation();
  const [shouldMapMatch, setShouldMapMatch] = React.useState(
    !!props?.canMapMatch
  );
  const [directionPage, setDirectionPage] = React.useState<number>(0);
  const {
    data: directionData,
    fetchNextRoutePage,
    hasNextRoutePage,
    isFetchingNextRoutePage,
  } = useInfiniteDirectionData(
    process.env.REACT_APP_MAPBOX_KEY!,
    data?.reports?.map((report: any) => [
      report?.position?.lon,
      report?.position?.lat,
    ]),
    shouldMapMatch && data?.reports?.length > 0 && !!props?.canMapMatch
  ) as any;

  const vehicleTripLayer: any = LineStringLayer({
    id: LAYERS.TRIP_VEHICLE_LINE,
    source: SOURCES.LINE_STRING,
    size: 3,
    ...(!!props?.canMapMatch &&
      shouldMapMatch && {
        dash: [2, 2],
        color: '#71c6ff',
        size: 2,
        opacity: 0.8,
      }),
    metadata: {
      rerender: shouldMapMatch,
    },
  });

  useEffect(() => {
    if (!!hasNextRoutePage && !isFetchingNextRoutePage) {
      fetchNextRoutePage();
    }
  }, [hasNextRoutePage, isFetchingNextRoutePage]);

  const combinedDirectionData = useMemo(() => {
    // If shouldMapMatch is false and reports are available, return them as is
    if (!shouldMapMatch && data?.reports?.length > 0) return data?.reports;

    const directionRouteData = directionData?.pages?.reduce(
      (acc: any, page: any) => {
        const route = page?.routes?.[0];
        if (!route) return acc;

        const distance = route.distance;
        const duration = route.duration;
        const routes = route.geometry.coordinates;

        return {
          waypoints: [
            ...acc.waypoints,
            ...page.waypoints.map((waypoint: any) => ({
              distance: waypoint.distance,
              location: waypoint.location,
              name: waypoint.name,
            })),
          ],
          routes: [
            {
              ...acc?.routes?.[0],
              distance: acc.distance ? acc.distance + distance : distance,
              duration: acc.duration ? acc.duration + duration : duration,
              geometry: {
                type: 'LineString',
                coordinates: acc?.routes?.[0]?.geometry?.coordinates
                  ? acc?.routes?.[0].geometry?.coordinates.concat(routes)
                  : routes,
              },
            },
          ],
        };
      },
      { waypoints: [], routes: [] }
    );

    if (directionRouteData?.routes?.length > 0) {
      const reportsAndWaypoints = data?.reports?.map(
        (report: any, index: number) => {
          return {
            ...report,
            waypoint: directionRouteData?.waypoints?.[index]?.location,
            timestamp: data?.reports?.[index]?.timestamp,
          };
        }
      );

      let lastFoundReport = -1;
      const waypointToDirectionDistance: any = [];

      const almostThere = directionRouteData?.routes?.[0]?.geometry?.coordinates
        ?.map((coord: any, index: number) => {
          const reportIndex = reportsAndWaypoints?.findIndex(
            (report: any, idx: number) =>
              idx > lastFoundReport &&
              JSON.stringify(coord) === JSON.stringify(report?.waypoint)
          );

          if (reportIndex === -1) {
            return {
              position: {
                lon: coord[0],
                lat: coord[1],
              },
            };
          }

          lastFoundReport = reportIndex;
          waypointToDirectionDistance[reportIndex] = index;

          const prev = reportsAndWaypoints?.[reportIndex - 1];
          const report = reportsAndWaypoints?.[reportIndex];

          return (
            report || {
              position: {
                lon: coord[0],
                lat: coord[1],
              },
              prev,
            }
          );
        })
        .filter((x: any) => x);

      let lastWaypoint = 0;
      const beforeSorting = almostThere.map((report: any, index: number) => {
        if (report?.waypoint) {
          const wpIndex = waypointToDirectionDistance?.findIndex(
            (wp: any) => wp === index
          );
          if (wpIndex) {
            lastWaypoint = wpIndex;
          }
          return { ...report, reportIndex: lastWaypoint };
        }

        const current = waypointToDirectionDistance[lastWaypoint];
        const next = waypointToDirectionDistance[lastWaypoint + 1];

        if (next) {
          const timeDiff = moment(almostThere?.[next]?.timestamp).diff(
            moment(almostThere?.[current]?.timestamp),
            'seconds'
          );

          const linearlyInterpolatedTime = moment(
            almostThere?.[current]?.timestamp
          ).add((timeDiff / (next - current)) * (index - current), 'seconds');

          return {
            ...almostThere?.[current],
            ...report,
            originalTimestamp: almostThere?.[current]?.timestamp,
            timestamp: linearlyInterpolatedTime.format(
              'YYYY-MM-DDTHH:mm:ss.SSS'
            ),
            reportIndex: lastWaypoint,
          };
        }

        return { ...report, reportIndex: lastWaypoint };
      });

      // Sort before removing duplicates
      beforeSorting.sort(
        (a: any, b: any) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );

      // Remove duplicates after sorting
      const res = removeDuplicatesAndSortByTimestamp(beforeSorting);
      console.log(res);

      return res;
    }

    return data?.reports;
  }, [directionData, data, shouldMapMatch]);

  useEffect(() => {
    if (combinedDirectionData?.length > 0) {
      setMapMatchedLineStringSource(
        LineStringSource(
          combinedDirectionData?.map((coord: any) => ({
            position: coord?.position,
          })),
          SOURCES.TRIP_LINE_MAP_MATCHED
        )
      );
    }
  }, [combinedDirectionData]);

  useEffect(() => {
    if (data?.reports) {
      const locationMapped = combinedDirectionData?.map(({ position }: any) => [
        parseFloat(position.lon),
        parseFloat(position.lat),
      ]);
      if (locationMapped.length === 0) return;

      const locationLineString = lineString(locationMapped);
      const tmpBbox = bboxPolygon(bbox(locationLineString));

      const locationBbox = bboxPolygon(
        bbox(
          buffer(
            locationLineString.geometry,
            Math.max(1, area(tmpBbox) / 1_000_000 / 1000),
            { units: 'kilometers' }
          )
        )
      );
      setLocationBbox(locationBbox);
    }
  }, [data?.reports]);

  React.useEffect(() => {
    if (data && data?.reports && combinedDirectionData?.length > 0) {
      setLineStringSource(LineStringSource(data?.reports));
      setTripEventsSource(TripEventsSource(data?.reports));
      setTripEndPointSource(
        PointSource(
          SOURCES.TRIP_END_POINT,
          combinedDirectionData?.[combinedDirectionData?.length - 1]
        )
      );
    }
  }, [data]);

  useEffect(() => {
    if (play) {
      const interval = setInterval(() => {
        setPlaybackProgress(
          prev => prev + playbackSpeeds[playbackSpeed] / UPDATES_PER_SECOND
        );
      }, 1000 / UPDATES_PER_SECOND);

      return () => clearInterval(interval);
    }
  }, [play, playbackSpeed]);

  const getCurrentReportIndex = (localPlaybackProgress: number) => {
    if (Math.floor(localPlaybackProgress) === tripDuration) {
      return combinedDirectionData?.length - 1;
    }

    const currentTimeProgress = moment(data?.reports?.[0]?.timestamp)
      .add(Math.floor(localPlaybackProgress), 'seconds')
      .milliseconds(999);

    const newIndex = combinedDirectionData?.reduce(
      (closestIndex: any, report: any, index: any) => {
        const reportTime = moment(report.timestamp);
        const closestReportTime = moment(
          combinedDirectionData?.[closestIndex]?.timestamp
        );

        // If the report time exactly matches the current progress
        if (reportTime.isSame(currentTimeProgress)) {
          return index;
        }

        // Otherwise, take the closest report that is before the current time
        if (reportTime.isSameOrBefore(currentTimeProgress)) {
          if (reportTime.isAfter(closestReportTime)) {
            return index;
          }

          // If the report times are the same and index is higher, take the later report
          if (reportTime.isSame(closestReportTime) && index > closestIndex) {
            return index;
          }
        }

        return closestIndex;
      },
      0
    );

    if (
      !highlightedReport?.highlightFromSlider &&
      highlightedReport?.index !== newIndex
    ) {
      return highlightedReport?.index;
    }
    return newIndex;
  };

  useEffect(() => {
    if (!!play)
      setHighlightedReport({
        index: highlightedReport.index,
        highlightFromSlider: true,
      });
  }, [play]);

  useEffect(() => {
    if (playbackProgress > tripDuration) {
      setPlay(false);
    }

    let mapReportIndex = 0;
    const currentReportIndex: number = (() => {
      const index = getCurrentReportIndex(playbackProgress);
      mapReportIndex = index;
      if (shouldMapMatch) {
        return combinedDirectionData?.[index]?.reportIndex ?? index;
      }
      return index;
    })();

    if (play) {
      virtuoso?.current?.scrollToIndex({
        index: currentReportIndex,
        behavior: 'instant',
        align: 'center',
      });
    }

    setHighlightedReport({
      index: currentReportIndex,
      highlightFromSlider: true,
    });

    const currentReport = combinedDirectionData?.[mapReportIndex];
    const nextReport = combinedDirectionData?.[mapReportIndex + 1];

    if (currentReport && nextReport) {
      const currentPosition = [
        currentReport.position.lon,
        currentReport.position.lat,
      ];
      const nextPosition = [nextReport.position.lon, nextReport.position.lat];

      const line = lineString([currentPosition, nextPosition]);
      const currentTimeProgress = moment(
        combinedDirectionData?.[0]?.timestamp
      ).add(playbackProgress, 'seconds');
      const currentReportTime = moment(currentReport.timestamp);
      const nextReportTime = moment(nextReport.timestamp);

      const totalDuration = nextReportTime.diff(currentReportTime);

      const elapsedTime = currentTimeProgress.diff(currentReportTime);

      const interpolationFactor =
        totalDuration === 0 || elapsedTime === 0
          ? 0
          : Math.max(0, elapsedTime / totalDuration);

      const totalDistance = length(line, { units: 'kilometers' });

      if (shouldMapMatch) {
        setAvgSpeed(currentReport.speed);
        setAvgHeading(currentReport.heading);
      } else {
        const tmpAvgSpeed =
          totalDistance === 0 || totalDuration === 0
            ? 0
            : totalDistance / (totalDuration / 3_600_000);

        setAvgSpeed(tmpAvgSpeed.toFixed(0));
        setAvgHeading(bearing(currentPosition, nextPosition, { final: true }));
      }

      const distanceAlongLine = totalDistance * interpolationFactor;

      const interpolatedPoint = along(line, Math.max(0, distanceAlongLine));

      const [interpolatedLon, interpolatedLat] =
        interpolatedPoint.geometry.coordinates;

      setDetailTripPointSource(
        SingleAssetLocationSource({
          ...currentReport,
          position: {
            lat: interpolatedLat,
            lon: interpolatedLon,
          },
        })
      );

      if (shouldFollow) {
        setCenter([interpolatedLon, interpolatedLat]);
      }
    }
  }, [data, playbackProgress, shouldFollow]);

  const tripDuration = useMemo(() => {
    if (combinedDirectionData?.length) {
      const firstReport = data?.reports[0];
      const lastReport = data?.reports[data?.reports.length - 1];
      return moment(lastReport.timestamp).diff(
        moment(firstReport.timestamp),
        'seconds'
      );
    }
    return 0;
  }, [data]);

  useEffect(() => {
    if (tripDuration > 0) {
      if (tripDuration < 120) {
        // < 2 minutes
        setPlaybackSpeed(0);
      } else if (tripDuration < 1800) {
        // < 30 minutes
        setPlaybackSpeed(1);
      } else if (tripDuration < 5400) {
        // < 1.5 hours
        setPlaybackSpeed(2);
      } else if (tripDuration < 10800) {
        // < 3 hours
        setPlaybackSpeed(3);
      } else {
        // > 3 hours
        setPlaybackSpeed(4);
      }
    }
  }, [tripDuration]);

  const updatePlaybackSpeed = () => {
    const nextSpeed = (playbackSpeed + 1) % playbackSpeeds.length;
    // setPlay(false);
    setPlaybackSpeed(nextSpeed);
  };

  const marks = useMemo(() => {
    if (!combinedDirectionData?.length || !tripDuration) return [];
    return data?.reports
      ?.filter((report: any) =>
        [
          'speeding_start',
          'harsh_acceleration',
          'harsh_brake',
          'harsh_turn',
          'antenna_disconnected',
          'gps_jamming_start',
          'lockout_engaged',
          'panic_start',
          'panic_event',
          'accident_detected',
        ].includes(report?.reportType)
      )
      .map((report: any, index: number) => {
        return {
          value: moment(report.timestamp).diff(
            moment(data?.reports?.[0]?.timestamp),
            'seconds'
          ),
          label: (
            <Tooltip
              title={t(`reports.report_type.${report?.reportType}`)}
              onClick={() => {
                setPlay(false);
                setShouldFollow(true);
                setHighlightedReport({
                  index,
                  highlightFromSlider: false,
                });
                setPlaybackProgress(
                  moment(report?.timestamp).diff(
                    moment(data?.reports?.[0]?.timestamp),
                    'seconds'
                  )
                );
              }}>
              <span>{renderReportTypeIndicator(report?.reportType)}</span>
            </Tooltip>
          ),
        };
      });
  }, [tripDuration, data]);

  React.useEffect(() => {
    console.log(combinedDirectionData);
  }, [combinedDirectionData]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1001,
              rippleColor: 'rgba(255,255,255,0.2)',
              backgroundColor: 'rgba(0,0,0,0.5)',
              marginRight: -2,
              marginTop: -2,
            }}>
            <Close />
          </IconButton>
          <Box
            sx={{
              p: 2,
              height: '100%',
            }}>
            {isLoading ? (
              <Skeleton
                width={'100%'}
                height={'100%'}
                sx={{ transform: 'none' }}
              />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={3.5}>
                  {!!combinedDirectionData?.length && (
                    <Virtuoso
                      ref={virtuoso}
                      data={data?.reports ?? []}
                      style={{ height: window.innerHeight * 0.7 - 32 }}
                      totalCount={parseInt(data?.numRecords ?? 0)}
                      itemContent={(index: number, item: any) => (
                        <ListItem
                          onClick={() => {
                            setPlay(false);
                            setShouldFollow(true);
                            setPlaybackProgress(
                              moment(item?.timestamp).diff(
                                moment(data?.reports?.[0]?.timestamp),
                                'seconds'
                              )
                            );
                          }}
                          styleObject={
                            highlightedReport?.index === index && {
                              backgroundColor: 'rgba(255,255,255,0.3)',
                            }
                          }>
                          <Grid container justifyContent={'space-between'}>
                            <Grid
                              item
                              xs={8}
                              justifyContent={'flex-start'}
                              display='flex'
                              alignItems='center'
                              flex={1}>
                              <Grid
                                item
                                flex={1}
                                display='flex'
                                direction='column'>
                                <div>
                                  {t(`reports.report_type.${item?.reportType}`)}
                                </div>
                                <div>
                                  <small style={{ fontSize: 12 }}>
                                    {item?.position?.lat?.toFixed(6)},{' '}
                                    {item?.position?.lon?.toFixed(6)}
                                  </small>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              justifyContent={'flex-end'}
                              display='flex'
                              alignItems='center'
                              flex={1}>
                              <Grid
                                item
                                flex={1}
                                display='flex'
                                justifyContent='space-between'
                                alignItems='flex-end'
                                direction='column'
                                sx={{ height: '100%' }}>
                                <small style={{ fontSize: 12 }}>
                                  {moment(item?.timestamp)
                                    .hours()
                                    .toString()
                                    .padStart(2, '0')}
                                  :
                                  {moment(item?.timestamp)
                                    .minutes()
                                    .toString()
                                    .padStart(2, '0')}
                                  :
                                  {moment(item?.timestamp)
                                    .seconds()
                                    .toString()
                                    .padStart(2, '0')}
                                </small>
                                {renderReportTypeIndicator(item?.reportType)}
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItem>
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={8.5}>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}>
                    <AltMap
                      height={'calc( 100% - 60px )'}
                      mapStyle={storedMapStyle}
                      googleAttributionStyle={{
                        position: 'absolute',
                        left: '100px',
                        bottom: '60px',
                        zIndex: 1,
                      }}
                      center={mapCenter ?? [0, 0]}
                      hasControls={false}
                      hasDraw={false}
                      images={[
                        tripEndImage,
                        tripPointImage,
                        tripEventIndicatorImage,
                        tripPanicIndicatorImage,
                      ]}
                      layers={[
                        ...(!!props?.canMapMatch && shouldMapMatch
                          ? [vehicleTripMapMatchedLayer]
                          : []),
                        vehicleTripLayer,
                        // assetTripPointLayer,
                        tripEndPointLayer,
                        tripEventsLayer,
                        tripDetailPointLayer,
                      ]}
                      sources={[
                        ...(!!props?.canMapMatch && shouldMapMatch
                          ? [mapMatchedlineStringSource]
                          : []),
                        lineStringSource,
                        // assetPointSource,
                        detailTripPointSource,
                        tripEndPointSource,
                        tripEventsSource,
                      ]}
                      bounds={shouldFollow ? null : locationBbox?.bbox}
                      // maxBounds={true}
                      zoom={16}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        color: 'white',
                        padding: '8px',
                        fontSize: 12,
                      }}>
                      <div>
                        {t('avg_speed')}: {avgSpeed} km/h
                      </div>
                      <div>
                        {t('avg_heading')}: {avgHeading.toFixed(0)}°{' '}
                        {getDirectionFromDeg(avgHeading)}
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 170,
                      }}>
                      <div style={{ width: '100%' }}>
                        <FormControlLabel
                          sx={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            m: 0,
                            padding: 0.5,
                            width: '100%',
                          }}
                          control={
                            <Checkbox
                              style={{
                                color: 'white',
                                padding: 0,
                              }}
                              disableRipple
                              checked={shouldFollow}
                              value={shouldFollow}
                              onChange={() => setShouldFollow(!shouldFollow)}
                            />
                          }
                          label={
                            <span
                              style={{
                                color: 'white',
                                paddingLeft: 8,
                                paddingRight: 8,
                              }}>
                              {t('Follow asset')}
                            </span>
                          }
                        />
                      </div>
                      {props?.canMapMatch && (
                        <div style={{ width: '100%' }}>
                          <FormControlLabel
                            sx={{
                              backgroundColor: 'rgba(0,0,0,0.5)',
                              m: 0,
                              padding: 0.5,
                              width: '100%',
                            }}
                            control={
                              <Checkbox
                                style={{
                                  color: 'white',
                                  padding: 0,
                                }}
                                disableRipple
                                checked={shouldMapMatch}
                                value={shouldMapMatch}
                                onChange={() =>
                                  setShouldMapMatch(!shouldMapMatch)
                                }
                              />
                            }
                            label={
                              <span
                                style={{
                                  color: 'white',
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                }}>
                                {t('Estimate Route')}
                              </span>
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        height: 60,
                        width: '100%',
                        backgroundColor: 'rgba(255,255,255,0.1)',
                      }}>
                      <Grid
                        container
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        height='100%'>
                        <Grid
                          item
                          xs={2}
                          paddingX={1}
                          display='flex'
                          alignItems='center'
                          justifyContent='center'>
                          <IconButton
                            onClick={() => {
                              if (playbackProgress > tripDuration) {
                                setPlaybackProgress(0);
                                setPlay(false);
                                return;
                              }
                              setPlay(!play);
                            }}>
                            {playbackProgress > tripDuration ? (
                              <FirstPageRounded />
                            ) : play ? (
                              <PauseRounded />
                            ) : (
                              <PlayArrowRounded />
                            )}
                          </IconButton>
                          <Button
                            sx={{ px: 1 }}
                            onClick={() => updatePlaybackSpeed()}>
                            {playbackSpeeds[playbackSpeed]}x
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          paddingX={1}
                          display='flex'
                          alignItems='center'>
                          <Grid
                            container
                            direction='column'
                            display='flex'
                            xs={12}>
                            <Stack
                              spacing={2}
                              direction='row'
                              alignItems='center'>
                              <span
                                style={{
                                  fontSize: 12,
                                  color: 'rgba(255,255,255,0.7)',
                                }}>
                                {moment(data?.reports?.[0]?.timestamp).format(
                                  'HH:mm:ss'
                                )}
                              </span>
                              <Slider
                                onClick={() =>
                                  setHighlightedReport({
                                    index: highlightedReport.index,
                                    highlightFromSlider: true,
                                  })
                                }
                                onChange={(_, value) => {
                                  setPlay(false);
                                  setPlaybackProgress(value as number);
                                  const currentReportIndex: number = (() => {
                                    const index = getCurrentReportIndex(
                                      value as number
                                    );
                                    if (shouldMapMatch) {
                                      return (
                                        combinedDirectionData?.[index]
                                          ?.reportIndex ?? index
                                      );
                                    }
                                    return index;
                                  })();
                                  virtuoso?.current?.scrollToIndex({
                                    index: currentReportIndex,
                                    behavior: 'instant',
                                    align: 'center',
                                  });
                                }}
                                valueLabelDisplay='on'
                                valueLabelFormat={value =>
                                  moment(combinedDirectionData?.[0]?.timestamp)
                                    .milliseconds(0)
                                    .add(value, 'seconds')
                                    .milliseconds(0)
                                    .format('HH:mm:ss')
                                }
                                step={0.001}
                                min={0}
                                max={tripDuration}
                                value={playbackProgress}
                                marks={marks}
                                sx={{
                                  width: 'calc( 100% - 20px )',
                                  zIndex: 2,
                                  '&.MuiSlider-marked': {
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                  },
                                }}
                              />
                              <span
                                style={{
                                  fontSize: 12,
                                  color: 'rgba(255,255,255,0.7)',
                                }}>
                                {moment(
                                  data?.reports?.[data?.reports?.length - 1]
                                    ?.timestamp
                                ).format('HH:mm:ss')}
                              </span>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default TripPlayback;
