import { useAssetsFilter } from 'hooks/filters/useFilters';
import { t } from 'i18next';
import React from 'react';
import Filter from './Filter';

const AssetFilter = ({
  updateFilters,
  filters,
  multiple,
  assetTypes,
  size = 'small',
}: any) => {
  const { data, isLoading } = useAssetsFilter({ assetTypes }) as any;

  return (
    <Filter
      data={data}
      isLoading={isLoading}
      multiple={multiple}
      filterName='asset'
      label={t(multiple ? 'Assets' : 'Asset')}
      filterKey={multiple ? 'assetIds' : 'assetId'}
      keyProperty='assetId'
      labelProperty='name'
      filters={filters}
      updateFilters={updateFilters}
      limitTags={multiple ? 0 : 1}
      size={size}
    />
  );
};

export default AssetFilter;
