import { Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  // eslint-disable-next-line import/named
  GridCellParams,
  // eslint-disable-next-line import/named
  GridColDef,
  // eslint-disable-next-line import/named
  MuiEvent,
} from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import useActionMenuContext from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import { useAssetAlerts } from 'hooks';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportWrapper from '../ReportWrapper';
import useUIContext from 'context/UIContext';

const commonColDefOptions = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

function getColumns({ t }: any): GridColDef[] {
  return [
    //@ts-ignore
    {
      field: 'alertType',
      headerName: t('Type'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return t(`alert.types.${r.row.alertType}`);
      },
    }, //@ts-ignore
    {
      field: 'startedAt',
      headerName: t('Started At'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return r?.row?.startedAt ? (
          <Tooltip
            arrow={true}
            title={moment(r?.row?.startedAt).format('YYYY-MM-DD HH:mm:ss')}>
            <Box>{moment(r?.row?.startedAt).fromNow()}</Box>
          </Tooltip>
        ) : (
          ''
        );
      },
    }, //@ts-ignore
    {
      field: 'null',
      headerName: t('Value'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        const property = r?.row?.alertType.includes('pressure')
          ? 'pressure'
          : 'temperature';
        const unit = r?.row?.alertType.includes('pressure') ? 'psi' : '°C';
        const sign = r?.row?.alertType.includes('high') ? '>' : '<';
        const triggerValue = r?.row?.details?.report[property];
        // limit property should be either maxPressure, minPressure, maxTemperature, minTemperature
        const limitProperty = `max${
          property.charAt(0).toUpperCase() + property.slice(1)
        }`;
        const limit = r?.row?.details?.assetDetails[limitProperty];

        return `${triggerValue} ${sign} ${limit} ${unit}`;
      },
    }, //@ts-ignore
    {
      field: 'endedAt',
      headerName: t('Ended At'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return r?.row?.endedAt ? (
          <Tooltip
            arrow={true}
            title={moment(r?.row?.endedAt).format('YYYY-MM-DD HH:mm:ss')}>
            <Box>{moment(r?.row?.endedAt).fromNow()}</Box>
          </Tooltip>
        ) : (
          ''
        );
      },
    }, //@ts-ignore
  ];
}
const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root': {
      cursor: 'pointer',
    },
  },
});

const Alerts = ({
  assetId,
  showFilters = true,
  hideColumnIndexes = [],
  boxed = true,
  assetIds = [],
  pageSize = 10,
}: any) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [searchParams]: any = useComplexSearchParams();
  const { data, isLoading } = useAssetAlerts({
    assetId,
    page: page + 1,
    pageSize,
    startTimestamp: searchParams?.startTimestamp,
    endTimestamp: searchParams?.endTimestamp,
  }) as any;
  const { reset } = useActionMenuContext();
  const { canWrite } = usePermissions();
  const [rowCountState, setRowCountState] = React.useState(0);
  const { direction } = useUIContext();

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  React.useEffect(() => {
    return () => reset();
  }, []);

  const classes = useStyles();

  const ContainerTag: any = boxed ? ReportWrapper : Box;

  return (
    <ContainerTag>
      <DataGrid
        disableSelectionOnClick={true}
        onCellClick={(
          params: GridCellParams,
          event: MuiEvent<React.MouseEvent>
        ) => {
          event.defaultMuiPrevented = true;
        }}
        className={classes.root}
        rows={data?.alerts || []}
        getRowId={row => row.alertId}
        columns={
          'rtl' === direction ? getColumns({ t }).reverse() : getColumns({ t })
        }
        pageSize={pageSize || 10}
        checkboxSelection={false}
        autoHeight
        loading={isLoading}
        rowHeight={52}
        rowCount={rowCountState}
        onPageChange={newPage => {
          setPage(newPage);
        }}
        paginationMode='server'
        pagination
      />
    </ContainerTag>
  );
};

export default Alerts;
