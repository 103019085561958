import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import usePermissions from 'context/PermissionContext';
import { useAuth, useFeatures } from '../../hooks';
import useUIContext, { DIR } from '../../context/UIContext';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import {
  ContactEmergencyTwoTone,
  CreditCardTwoTone,
  GroupsTwoTone,
  HubTwoTone,
  LocationOnTwoTone,
  ManageAccountsTwoTone,
  NotificationImportantTwoTone,
  PersonTwoTone,
  RouterTwoTone,
  AppSettingsAltTwoTone,
  ScoreboardTwoTone,
  SouthAmericaTwoTone,
} from '@mui/icons-material';
import DriverStatusAltIconSmall from '../../components/CustomIcons/DriverStatusAltIconSmall';

interface LinkTabProps {
  label: string;
  icon?: any;
  iconSvg?: boolean;
  href?: string;
  disabled?: boolean;
  section?: boolean;
  dir: DIR;
  handleChange?: any;
  index?: number;
  tabIndex?: number;
}

function LinkTab(props: LinkTabProps) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  const { icon: Icon } = props;

  return (
    <Tab
      dir={props.dir}
      className='custom-tab-item'
      sx={{
        minHeight: 18,
        alignItems: 'start',
        py: 1,
        paddingLeft: 0,
      }}
      tabIndex={props.tabIndex}
      component='a'
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        if (!!props.handleChange) props.handleChange(event, props.index);
        if (!!props.href) navigate(props.href);
      }}
      disabled={!!props.disabled}
      label={
        <div
          style={{
            textAlign: DIR.RTL === props.dir ? 'right' : 'left',
            display: 'flex',
            alignContent: 'center',
            marginBottom: props.iconSvg ? '-6px' : 'auto',
          }}>
          {Icon && (
            <>
              <Icon sx={{ fontSize: 18 }} />
              {'  '}
            </>
          )}
          {t('settings.tabs.' + props.label)}
        </div>
      }
    />
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabItems = [
  {
    label: 'General',
    permission: null,
    href: '/settings/profile',
    section: true,
    icon: SettingsTwoToneIcon,
  },
  {
    divider: true,
  },
  {
    label: 'Administration',
    permission: null,
    disabled: true,
    section: true,
    children: [
      {
        label: 'Assets & Devices',
        permission: 'ASSET',
        href: '/settings/assets',
        icon: RouterTwoTone,
      },
      // {
      //   label: 'Configuration Profiles',
      //   permission: 'ASSET',
      //   href: '/settings/devices/profiles',
      //   icon: AppSettingsAltTwoTone,
      // },
      {
        label: 'Locations',
        permission: 'LOCATION',
        href: '/settings/locations',
        icon: LocationOnTwoTone,
      },
      {
        label: 'User Accounts',
        permission: 'USER',
        href: '/settings/users',
        icon: PersonTwoTone,
      },
      {
        label: 'Roles & Permissions',
        permission: 'ROLE',
        href: '/settings/roles',
        icon: ManageAccountsTwoTone,
      },
      {
        label: 'Groups',
        permission: 'GROUP',
        href: '/settings/groups',
        icon: GroupsTwoTone,
      },
    ],
  },
  {
    divider: true,
  },
  {
    label: 'Contacts & Notifications',
    permission: null,
    disabled: true,
    section: true,
    children: [
      {
        label: 'Contacts & Details',
        permission: 'CONTACT',
        href: '/settings/contacts',
        icon: ContactEmergencyTwoTone,
      },
      {
        label: 'Notification Rules',
        permission: 'NOTIFICATION',
        href: '/settings/notifications',
        icon: NotificationImportantTwoTone,
      },
    ],
  },
  {
    divider: true,
  },
  {
    label: 'Fleet Management',
    permission: null,
    disabled: true,
    section: true,
    children: [
      {
        label: 'Drivers',
        permission: 'DRIVER',
        userCapabilities: ['hasVehicles'],
        href: '/settings/drivers',
        icon: DriverStatusAltIconSmall,
        iconSvg: true,
      },
      {
        label: 'Driver Score',
        permission: 'DRIVER',
        userCapabilities: ['hasVehicles'],
        href: '/settings/driver-scorecard',
        icon: ScoreboardTwoTone,
      },
    ],
  },
  {
    divider: true,
  },
  {
    label: 'Organization',
    permission: null,
    disabled: true,
    section: true,
    userCapabilities: ['isOwner'],
    children: [
      {
        label: 'Billing',
        permission: 'BILLING',
        href: '/settings/billing',
        userCapabilities: ['isOwner'],
        icon: CreditCardTwoTone,
      },
      {
        label: 'Integrations',
        href: '/settings/integrations',
        userCapabilities: ['isOwner'],
        icon: HubTwoTone,
      },
    ],
  },
  {
    divider: true,
  },
  {
    label: 'Extras',
    permission: null,
    disabled: true,
    section: true,
    userCapabilities: ['isOwner'],
    children: [
      {
        label: 'AIS Regions',
        permission: 'AIS_REGIONS',
        href: '/settings/ais-regions',
        icon: SouthAmericaTwoTone,
      },
    ],
  },
];

const findIndexByHref = (data: any, href: string): any => {
  let flatIndex = -1;

  const findIndexRecursive: any = (data: any, href: string): any => {
    for (let i = 0; i < data.length; i++) {
      flatIndex++;
      const item = data[i];

      if (item.href === href) {
        return flatIndex;
      }

      if (item.children && item.children.length > 0) {
        const foundIndex = findIndexRecursive(item.children, href);
        if (foundIndex !== -1) {
          return foundIndex;
        }
      }
    }

    return -1;
  };

  return findIndexRecursive(data, href);
};

const renderSettingsMenu = (
  items: any,
  user: any,
  canRead: any,
  direction: DIR,
  handleChange: any
) => {
  let tabIndex = 0;
  const renderItems = (
    itemsList: any,
    parentIndex: string = '',
    direction: DIR
  ) => {
    let isPreviousDivider = false;
    return itemsList.flatMap((item: any, index: number) => {
      if (item.divider) {
        if (!isPreviousDivider) {
          isPreviousDivider = true;
          tabIndex++;
          return <Divider sx={{ my: 1 }} />;
        }
        return;
      }

      const currentIndex = `${parentIndex}${index}`;
      let renderedItems = [];

      // Check for user capabilities
      if (item.userCapabilities) {
        const existsOnUser = item.userCapabilities.every((c: any) => !!user[c]);
        if (!existsOnUser) return [];
      }

      // Check for permissions
      if (item.permission && !canRead(item.permission.toUpperCase())) return [];

      // If it's a section, check if it has any children the user can see
      if (item.section && item.children) {
        const childItems = renderItems(
          item.children,
          `${currentIndex}-`,
          direction
        );
        if (childItems.length > 0) {
          tabIndex++;
          renderedItems.push(
            <LinkTab
              dir={direction}
              key={currentIndex}
              label={item.label}
              href={item.href}
              disabled={item.disabled}
              section={item.section}
              handleChange={handleChange}
              index={tabIndex}
              {...a11yProps(parseInt(currentIndex))}
            />
          );
          renderedItems.push(...childItems);
        }
      } else {
        tabIndex++;
        renderedItems.push(
          <LinkTab
            dir={direction}
            key={currentIndex}
            label={item.label}
            href={item.href}
            disabled={item.disabled}
            icon={item.icon}
            iconSvg={item.iconSvg}
            handleChange={handleChange}
            index={tabIndex}
            {...a11yProps(parseInt(currentIndex))}
          />
        );
      }

      if (renderedItems.length > 0) {
        isPreviousDivider = false;
      }

      return renderedItems;
    });
  };

  return renderItems(items, undefined, direction);
};

const Settings = () => {
  const [value, setValue] = React.useState<number | null>(null);
  const location = useLocation();
  const { canRead } = usePermissions();
  const { user } = useAuth() as any;
  let navigate = useNavigate();
  const { fixRightSidebar, closeRightSidebar, openRightSidebar, direction } =
    useUIContext();

  useEffect(() => {
    if ('/settings/ais-regions' !== location.pathname) {
      fixRightSidebar(false);
      closeRightSidebar();
    } else {
      fixRightSidebar(true);
      openRightSidebar();
    }
  }, [location]);

  useEffect(() => {
    if (location && location.pathname) {
      if (location.pathname === '/settings') {
        if (tabItems[0] && tabItems[0].href) {
          navigate(tabItems[0].href);
          return;
        }
      }

      const matchingIndex = tabItems.findIndex(
        item => item.href === location.pathname
      );

      if (matchingIndex !== -1) {
        setValue(matchingIndex);
      }
    }
  }, [location, tabItems, setValue, navigate]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: 'calc (100% - 400px)',
        flex: 1,
        display: 'flex',
        paddingRight: 'auto',
      }}
      dir={direction}>
      <Box
        sx={{
          ...(DIR.RTL === direction ? { borderLeft: 1 } : { borderRight: 1 }),
          borderColor: 'divider',
          minWidth: 250,
        }}>
        <Tabs value={value} aria-label='settings tabs' orientation='vertical'>
          {renderSettingsMenu(tabItems, user, canRead, direction, handleChange)}
        </Tabs>
      </Box>
      <Box
        sx={{
          width: 'calc( 100% - 200px )',
          ...(DIR.RTL === direction
            ? { marginRight: '80px' }
            : { marginLeft: '80px' }),
        }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Settings;
