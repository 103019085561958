import { Box, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import InfoHelper from 'components/Tooltip/InfoHelper';
import useActionMenuContext from 'context/ActionMenuContext';
import { useToMobileLog } from 'hooks';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import ReportWrapper from 'views/Reports/ReportWrapper';
import ToMobileLogToolbarWithUrlFilters from './ToMobileLogFilters';
import useUIContext from 'context/UIContext';

const commonColDefOptions = {
  resizable: false,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filtrable: false,
  hideable: false,
};

function getColumns({ t }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'asset',
      headerName: t('Asset'),
      ...commonColDefOptions,
      flex: 1.5,
      renderCell: (r: any) => {
        return r?.row.asset.name;
      },
    }, // @ts-ignore
    // {
    //   field: 'assetType',
    //   headerName: t('Asset type'),
    //   ...commonColDefOptions,
    //   flex: 0.75,
    //   renderCell: (r: any) => {
    //     return t(`asset.types.${r?.row.asset.type}`);
    //   },
    // }, // @ts-ignore
    {
      field: 'type',
      headerName: t('Message type'),
      ...commonColDefOptions,
      flex: 1,
      renderCell: (r: any) => {
        if (r?.row.type === 'raw') {
          return (
            <Tooltip arrow={true} title={r?.row.raw}>
              <div>{t(`tm_message.type.${r?.row.type}`)}</div>
            </Tooltip>
          );
        }
        if (r?.row.type === 'ignition_lockout') {
          const fields = r?.row?.fields;
          if (fields?.lockoutState === true) {
            return t(`tm_message.type.engaged_lockout`);
          }
          return t(`tm_message.type.disengaged_lockout`);
        }
        return t(`tm_message.type.${r?.row.type}`);
      },
    }, // @ts-ignore
    {
      field: 'status',
      headerName: t('Status'),
      ...commonColDefOptions,
      flex: 0.75,
      renderCell: (r: any) => {
        if (r?.row.status === 'failed') {
          return (
            <Typography
              variant='body2'
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
              {t(`tm_message.status.${r?.row.status}`)}{' '}
              <InfoHelper label={r?.row.error} sx={{ ml: 1 }} />
            </Typography>
          );
        }
        return t(`tm_message.status.${r?.row.status}`);
      },
    }, // @ts-ignore
    {
      field: 'sentOver',
      headerName: t('Sent over'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return r?.row.sentOver
          ? t(`tm_message.transport.${r?.row.sentOver}`)
          : '';
      },
    }, // @ts-ignore
    {
      field: 'timestamp',
      headerName: t('Timestamp'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return moment(r?.row.timestamp).format('YYYY-MM-DD HH:mm:ss');
      },
    }, // @ts-ignore
    {
      field: 'user',
      headerName: t('User'),
      ...commonColDefOptions,
      flex: 2,
      renderCell: (r: any) => {
        return (
          <Tooltip arrow={true} title={r?.row.user.email}>
            <div>{r?.row.user.name}</div>
          </Tooltip>
        );
      },
    }, // @ts-ignore
  ];
}

const ToMobileLog = ({
  page = 1,
  setPage,
  toggleButtons,
}: {
  page: number;
  setPage: any;
  toggleButtons: any;
}) => {
  const { t } = useTranslation();
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchParams] = useComplexSearchParams();
  const { reset } = useActionMenuContext();
  const {
    data: tmData,
    isLoading: tmIsLoading,
    refetch: tmRefetch,
  } = useToMobileLog({
    page: page + 1,
    filterBy: searchParams,
    enabled: true,
  }) as any;
  const { direction } = useUIContext();

  React.useEffect(() => {
    setRowCountState(tmData?.numRecords ?? 0);
  }, [tmData?.numRecords, setRowCountState]);

  return (
    <ReportWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ width: '100%' }}>
          {toggleButtons}
          <ToMobileLogToolbarWithUrlFilters
            onRefreshClicked={() => tmRefetch()}
          />
        </Box>
      </Box>
      <DataGrid
        rows={tmData && tmData.messages ? tmData.messages : []}
        getRowId={row => row.messageId}
        columns={
          'rtl' === direction ? getColumns({ t }).reverse() : getColumns({ t })
        }
        pageSize={10}
        checkboxSelection={false}
        autoHeight
        loading={tmIsLoading}
        rowHeight={40}
        rowsPerPageOptions={[10]}
        rowCount={rowCountState}
        onPageChange={newPage => setPage(newPage)}
        paginationMode='server'
        pagination
      />
    </ReportWrapper>
  );
};

export default ToMobileLog;
