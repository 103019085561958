import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { apiClient } from 'services/axios';

export function useIntegrations(): UseQueryResult {
  return useQuery(
    ['integrations'],
    () => apiClient.get(`organizations/integrations`),
    {
      refetchOnWindowFocus: 'always',
    }
  );
}

export function useAddIntegration(): any {
  const queryClient = useQueryClient();
  return useMutation(
    'integrations',
    (variables: any) => apiClient.post('organizations/integrations', variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('integrations', { exact: false });
      },
    }
  );
}

export function useIntegration(variables?: {
  type: string | null | undefined;
}): UseQueryResult {
  return useQuery(
    ['integration', variables],
    () => apiClient.get(`organizations/integrations/${variables?.type}`),
    {
      enabled: !!variables?.type,
      staleTime: 1000 * 10,
      retry: (failureCount, error: any) => {
        if (error.status === 404) return false;
        else if (failureCount < 1) return true;
        else return false;
      },
      refetchOnWindowFocus: query => query.state.status !== 'error',
    }
  );
}

export function useDeleteIntegration(): any {
  const queryClient = useQueryClient();
  return useMutation(
    ['integration'],
    (type: string) =>
      apiClient.delete(`organizations/integrations`, { data: { type } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('integrations');
      },
    }
  );
}
