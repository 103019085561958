import { DataGrid } from '@mui/x-data-grid/DataGrid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/named
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { IntervalType } from 'components/Filters/DateFilter';
import FuelLineChart from 'components/Reports/Widgets/FuelLineChart';
import useActionMenuContext from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import {
  FuelReportSortByType,
  FuelReportSortOrderType,
  useAssetsFuel,
  useExportFuelReport,
} from 'hooks/assets/useAssets';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import ExportReportButton from '../ExportReportButton';
import ReportWrapper from '../ReportWrapper';
import AssetUtilizationToolbarWithUrlFilters from '../Utilization/AssetUtilizationFilters';

const commonColDefOptions = {
  // resizable: false,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filtrable: false,
  hideable: false,
  disableColumnMenu: true,
};

const formatMinutes = function (minutes: number) {
  if (minutes < 60) {
    return `${minutes}m`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};

function getColumns({ t }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'assetName',
      headerName: t('Asset'),
      ...commonColDefOptions,
      flex: 1,
    }, // @ts-ignore
    {
      field: 'formattedAddress',
      headerName: t('Last Reported Location'),
      ...commonColDefOptions,
      flex: 1.5,
    }, // @ts-ignore
    {
      field: 'fuelEconomy',
      headerName: t('avg_fuel_economy'),
      ...commonColDefOptions,
      // sortable: true,
      flex: 1.2,
      renderCell: (r: any) => {
        if (r?.row?.fuelEconomy === 0) {
          return (
            <div>
              <Tooltip
                arrow={true}
                title={t('asset_no_fuel_data_for_time_period')}>
                <div>{t('N/A')}</div>
              </Tooltip>
            </div>
          );
        }
        return `${r?.row?.fuelEconomy.toFixed(2)}L / 100km`;
      },
    }, // @ts-ignore
    {
      field: 'fuelLts',
      headerName: t('Fuel Consumed'),
      ...commonColDefOptions,
      sortable: true,
      flex: 0.75,
      renderCell: (r: any) => {
        if (r?.row?.fuelLts === 0) {
          return (
            <div>
              <Tooltip
                arrow={true}
                title={t('asset_no_fuel_data_for_time_period')}>
                <div>{t('N/A')}</div>
              </Tooltip>
            </div>
          );
        }
        return `${r?.row?.fuelLts.toFixed(2)}L`;
      },
    }, // @ts-ignore
    {
      field: 'distanceKm',
      headerName: t('Distance driven'),
      ...commonColDefOptions,
      sortable: true,
      flex: 0.75,
      renderCell: (r: any) => {
        return `${r?.row?.distanceKm}km`;
      },
    }, // @ts-ignore
    {
      field: 'idlingMinutes',
      headerName: t('Idling Time'),
      ...commonColDefOptions,
      sortable: true,
      flex: 0.75,
      renderCell: (r: any) => {
        return formatMinutes(r?.row?.idlingMinutes);
      },
    }, // @ts-ignore
    {
      field: 'co2Kg',
      ...commonColDefOptions,
      sortable: true,
      flex: 0.75,
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {t('Total')}
          {''}
          CO<sub>2</sub>
        </span>
      ),
      renderCell: (r: any) => {
        if (r?.row?.co2Kg === 0) {
          return (
            <div>
              <Tooltip
                arrow={true}
                title={t('asset_no_fuel_data_for_time_period')}>
                <div>N/A</div>
              </Tooltip>
            </div>
          );
        }
        return `${r?.row?.co2Kg.toFixed(2)}kg`;
      },
    }, // @ts-ignore
    {
      field: 'co2gPerKm',
      ...commonColDefOptions,
      sortable: true,
      flex: 0.75,
      renderHeader: (params: GridColumnHeaderParams) => (
        <span>
          {'CO'}
          <sub>2</sub>
          {'/km'}
        </span>
      ),
      renderCell: (r: any) => {
        if (r?.row?.co2GPerKm === 0) {
          return (
            <div>
              <Tooltip
                arrow={true}
                title={t('asset_no_fuel_data_for_time_period')}>
                <div>N/A</div>
              </Tooltip>
            </div>
          );
        }
        return `${r?.row?.co2GPerKm.toFixed(2)}g`;
      },
    }, // @ts-ignore
  ];
}

const ExportAssetLog = ({ filters }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const [closePopover, setClosePopover] = React.useState<boolean>(false);

  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportFuelReport();

  const handleExport = (fileType: any) => {
    setClosePopover(true);
    exportReport({ ...filters, fileType });
  };

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  return (
    <>
      <ExportReportButton
        isExporting={isExporting}
        handleExport={handleExport}
      />
      {exportSuccess && exportData && exportData.reportUrl && (
        <iframe
          width='1pxre'
          height='1px'
          style={{ visibility: 'hidden', position: 'absolute' }}
          referrerPolicy='origin'
          src={exportData.reportUrl}></iframe>
      )}
    </>
  );
};

const AssetFuel = ({ assetType }: any) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchParams]: any = useComplexSearchParams();
  const [sortBy, setSortBy] = useState<FuelReportSortByType>('fuelEconomy');
  const [sortOrder, setSortOrder] = useState<FuelReportSortOrderType>('DESC');
  const { direction } = useUIContext();

  const { data, isLoading } = useAssetsFuel({
    page: page + 1,
    filterBy: searchParams,
    sortBy,
    sortOrder,
  }) as any;
  const { reset } = useActionMenuContext();
  const { canWrite, canRead } = usePermissions();

  React.useEffect(() => {
    return () => reset();
  }, []);

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  return (
    <ReportWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ width: '100%' }}>
          <AssetUtilizationToolbarWithUrlFilters
            type={'fuel'}
            assetType={assetType}
            exportComponent={ExportAssetLog}
          />
        </Box>
      </Box>
      <Box
        className='widget-container'
        key={1}
        sx={{ mb: 2, minHeight: '200px' }}>
        <div className='widget-content'>
          <FuelLineChart
            assetType='vehicle'
            widgetType='fuel'
            chartUnit='%'
            showTotal={false}
            startTimestamp={searchParams?.startTimestamp}
            endTimestamp={searchParams?.endTimestamp}
            groupIds={searchParams?.groupIds}
            interval={
              searchParams?.dateRange === IntervalType.LAST_THREE_MONTHS ? 6 : 0
            }
          />
        </div>
      </Box>
      <DataGrid
        rows={data && data.reports ? data.reports : []}
        getRowId={row => row.assetId}
        columns={
          'rtl' === direction ? getColumns({ t }).reverse() : getColumns({ t })
        }
        pageSize={10}
        checkboxSelection={false}
        autoHeight
        loading={isLoading}
        rowHeight={40}
        rowsPerPageOptions={[10]}
        rowCount={rowCountState}
        onPageChange={newPage => setPage(newPage)}
        paginationMode='server'
        pagination
        sortingOrder={['desc', 'asc']}
        sortingMode='server'
        onSortModelChange={({ 0: sorting }) => {
          if (!sorting) {
            setSortBy('fuelEconomy');
            setSortOrder('DESC');
            return;
          }
          const { field, sort } = sorting;
          if (field) setSortBy(field as FuelReportSortByType);
          if (sort) setSortOrder(sort.toUpperCase() as FuelReportSortOrderType);
        }}
      />
      <div style={{ marginTop: '0.1em', lineHeight: '1em' }}>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          {t('asset_fuel_reports_disclaimer_1')}
        </Typography>
      </div>
      <div style={{ marginTop: '0.1em', lineHeight: '1em' }}>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          {t('asset_fuel_reports_disclaimer_2')}
        </Typography>
      </div>
      <div style={{ marginTop: '0.1em', lineHeight: '1em' }}>
        <Typography variant={'caption'} sx={{ color: 'rgba(255,255,255,0.5)' }}>
          {t('asset_fuel_reports_disclaimer_3')}
        </Typography>
      </div>
    </ReportWrapper>
  );
};

export default AssetFuel;
