import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
  Alert,
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
} from '@mui/material';
import AutoComplete from 'components/AutoComplete';
import MapStyles from 'components/Map/constants/styles';
import useActionMenuContext from 'context/ActionMenuContext';
import useUIContext from 'context/UIContext';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import {
  useCurrentUser,
  useGroups,
  useLocalStorage,
  useUpdateCurrentUser,
} from 'hooks';
import { useDisableMfa } from 'hooks/auth/useTwoFa';
import countries from 'i18n-iso-countries';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserProfileFormSchema from 'schemas/EditUserProfile';
import errorMessage from 'services/errorMessage';
import getTimeZoneOptions from 'services/timezones';
import TwoFa from './TwoFa';
import moment from 'moment';
const getCountryISO2 = require('country-iso-3-to-2');
const getCountryISO3 = require('country-iso-2-to-3');

export const languageOptions = [
  { label: 'English', value: 'en', flagIso: 'gb' },
  { label: 'Afrikaans', value: 'af', flagIso: 'za' },
  { label: 'العربية', value: 'ar', flagIso: 'sa' },
  { label: 'Deutsch', value: 'de', flagIso: 'de' },
  { label: 'Français', value: 'fr', flagIso: 'fr' },
  { label: 'Español', value: 'es', flagIso: 'es' },
  { label: 'Português', value: 'pt', flagIso: 'pt' },
  ...(['local', 'development'].includes(process.env.NODE_ENV)
    ? [
        {
          label: 'DEBUG',
          value: 'zzzzzz',
        },
      ]
    : []),
];

const FormFields = ({
  mutationError,
  isSuccess,
  setDirection,
  groupIds,
  userLoading,
  groups,
  groupsLoading,
}: any) => {
  const { t, i18n } = useTranslation();
  const { reset } = useActionMenuContext();
  const [serverError, setServerError] = useState<any>();
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage(
    'language',
    'en'
  );
  const {
    values,
    dirty,
    isSubmitting,
    resetForm,
    submitForm,
    isValid,
    setFieldValue,
    errors,
  }: any = useFormikContext();

  useEffect(() => {
    if (isSubmitting) {
      setServerError(null);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess]);

  useEffect(() => {
    setServerError(null);
  }, [values]);

  useEffect(() => {
    setServerError(mutationError);
  }, [mutationError]);

  useEffect(() => {
    if (!selectedLanguage) return;

    setFieldValue('language', selectedLanguage);
    document.documentElement.lang = selectedLanguage;
  }, [selectedLanguage, values]);

  const audibleAlertOptions = [
    { label: 'Off', value: 'off' },
    { label: 'Play once', value: 'once' },
    { label: 'Play repeatedly', value: 'loop' },
  ];

  const countriesList: any = Object.entries(
    countries.getNames('en', { select: 'official' })
  ).map(el => {
    return {
      id: el[0],
      value: el[1],
    };
  });

  return (
    <Form>
      <Collapse in={serverError}>
        <Alert variant='outlined' severity='error' sx={{ mb: 1 }}>
          {errorMessage(t, serverError)}
        </Alert>
      </Collapse>
      <div className='pv-10'>
        {values.language && (
          <Field
            component={AutoComplete}
            name={'language'}
            value={languageOptions.find(
              (option: any) => option.value === values.language
            )}
            textFieldProps={{
              label: t('profile.language.label'),
              placeholder: t('profile.language.placeholder'),
            }}
            onChange={(_: any, v: any) => {
              i18n.changeLanguage(v.value);
              if (['ar'].includes(v.value)) {
                setDirection('rtl');
              } else {
                setDirection('ltr');
              }
              moment.locale(v.value);
              setSelectedLanguage(v.value);
              document.documentElement.lang = v.value;
            }}
            options={languageOptions}
            getOptionLabel={(option: any) => option.label || ''}
            disableClearable={true}
            isOptionEqualToValue={(item: any, current: any) =>
              item?.value === current?.value
            }
          />
        )}
      </div>
      <div className='pv-10'>
        <Field
          disabled
          component={FormikTextField}
          sx={{ width: '100%' }}
          name='email'
          type='email'
          variant='outlined'
          label={t('user_email')}
          placeholder={t('user_email')}
        />
      </div>
      <div className='pv-10'>
        <Field
          component={FormikTextField}
          sx={{ width: '100%' }}
          name='name'
          type='text'
          label={t('user_name')}
          placeholder={t('user_name')}
        />
      </div>
      <div className='pv-10'>
        <MuiPhoneNumber
          defaultCountry={'ae'}
          onChange={e => setFieldValue('mobileNumber', e)}
          variant='outlined'
          placeholder='Enter phone'
          disableAreaCodes={true}
          disableDropdown={false}
          fullWidth={true}
          error={errors.mobileNumber}
          helperText={errors.mobileNumber}
          value={values.mobileNumber}
        />
      </div>
      <div className='pv-10'>
        <Grid
          container
          sx={{
            px: 0,
            alignItems: 'middle',
            justifyContent: 'space-between',
          }}>
          <Grid item xs={6} sx={{ pr: 1 }}>
            <Field
              component={AutoComplete}
              sx={{ width: '100%' }}
              onChange={(e: any, value: any) => {
                setFieldValue('settings.timezone', value.value);
              }}
              options={getTimeZoneOptions()}
              isOptionEqualToValue={(item: any, current: any) =>
                item?.value === current?.value
              }
              value={getTimeZoneOptions().find(
                (option: any) =>
                  option.value === (values.settings?.timezone ?? 'UTC')
              )}
              name='settings.timezone'
              getOptionLabel={(option: any) => option.label}
              disableClearable={true}
              textFieldProps={{
                label: t('Timezone'),
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ pl: 1 }}>
            <Field
              component={AutoComplete}
              name='settings.countryCode'
              onChange={(e: any, value: any) => {
                setFieldValue('settings.countryCode', getCountryISO3(value.id));
              }}
              value={
                values.settings?.countryCode
                  ? countriesList.find((option: any) => {
                      return (
                        option.id ===
                        getCountryISO2(values.settings?.countryCode)
                      );
                    })
                  : null
              }
              getOptionLabel={(option: any) => option.value}
              textFieldProps={{
                label: t('register.country'),
                placeholder: t('register.country'),
              }}
              options={countriesList}
            />
          </Grid>
        </Grid>
      </div>
      <div className='pv-10'>
        <Field
          component={AutoComplete}
          sx={{ width: '100%' }}
          onChange={(e: any, value: any) => {
            setFieldValue('settings.audibleAlertSetting', value.value);
          }}
          options={audibleAlertOptions}
          isOptionEqualToValue={(item: any, current: any) =>
            item?.value === current?.value
          }
          ip
          value={audibleAlertOptions.find(
            (option: any) =>
              option.value === (values.settings?.audibleAlertSetting ?? 'off')
          )}
          name='settings.audibleAlertSetting'
          getOptionLabel={(option: any) =>
            t(`settings.audibleAlertOptions.${option.label}`) || ''
          }
          disableClearable={true}
          InputProps={{ style: { fontSize: 30 } }}
          textFieldProps={{
            label: t('Audible Alert Setting'),
            style: { fontSize: 10 },
          }}
        />
      </div>
      <div className='pv-10'>
        <Field
          component={AutoComplete}
          sx={{ width: '100%' }}
          onChange={(e: any, value: any) => {
            setFieldValue('settings.mapStyle', value.value);
          }}
          options={Object.keys(MapStyles).map((key: any) => ({
            label: t(`mapStyles.${key}`),
            value: key,
          }))}
          isOptionEqualToValue={(item: any, current: any) =>
            item?.value === current?.value
          }
          value={
            values.settings.mapStyle
              ? {
                  label: t(`mapStyles.${values.settings.mapStyle}`),
                  value: values.settings.mapStyle,
                }
              : null
          }
          name='settings.mapStyle'
          getOptionLabel={(option: any) => option.label || ''}
          disableClearable={true}
          textFieldProps={{
            label: t('Map layer style'),
          }}
        />
      </div>
      <div className='pv-10'>
        {t("You're in the following groups:")}
        <div style={{ marginTop: 4 }}>
          {groupsLoading || (userLoading && <LinearProgress />)}
          {groups
            ?.filter((group: any) => (groupIds ?? [])?.includes(group.groupId))
            .map((group: any) => (
              <>
                <Chip
                  key={group.groupId}
                  label={group.name}
                  variant='outlined'
                />
                &nbsp;
              </>
            ))}
        </div>
      </div>
      <Divider sx={{ my: 1 }} />
      {isSubmitting && <LinearProgress />}
      <div style={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
        <Button
          onClick={submitForm}
          variant='contained'
          color='primary'
          sx={{ width: '50%' }}
          disabled={!isValid}>
          {t('profile.update_profile_button')}
        </Button>
      </div>
    </Form>
  );
};

const Profile = () => {
  const { t } = useTranslation();
  const { setFlashMessage, setDirection } = useUIContext();
  const { mutate, error, isSuccess } = useUpdateCurrentUser();
  const {
    mutate: disableMfa,
    error: disableMfaError,
    isSuccess: disableMfaSuccess,
  } = useDisableMfa();
  const { data, isLoading: userLoading }: any = useCurrentUser();
  const { data: groups, isLoading: groupsLoading }: any = useGroups();
  const [open, setOpen] = React.useState(false);
  const [openDisableDialog, setOpenDisableDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDisableMfa = () => {
    disableMfa();
    handleCloseDisableDialog();
  };

  const handleCloseDisableDialog = () => {
    setOpenDisableDialog(false);
  };

  const handleClickOpenDisableDialog = () => {
    setOpenDisableDialog(true);
  };

  const updateUser = (data: any) => {
    mutate({
      ...data,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setFlashMessage({ message: t('success.update_profile') });
    }
  }, [isSuccess]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={8} sx={{ pt: '0 !important', display: 'flex' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'nowrap',
                }}>
                {data?.mfaEnabled && (
                  <>
                    <VerifiedUserIcon sx={{ mr: 1 }} color='success' />
                    {t('2fa.status.enabled')}
                  </>
                )}
                {!data?.mfaEnabled && (
                  <>
                    <RemoveModeratorIcon sx={{ mr: 1 }} color='warning' />
                    {t('2fa.status.disabled')}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={4}>
              {data?.mfaEnabled && (
                <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
                  <Button
                    color='error'
                    size='small'
                    onClick={handleClickOpenDisableDialog}>
                    {t('2fa.status.btn_disable')}
                  </Button>
                </Box>
              )}
              {!data?.mfaEnabled && (
                <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
                  <Button
                    color='warning'
                    size='medium'
                    variant='outlined'
                    onClick={handleClickOpen}>
                    {t('2fa.status.btn_enable')}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Divider sx={{ mb: 4 }} />
          <Box>
            {data && (
              <Formik
                isInitialValid={false}
                initialValues={data}
                enableReinitialize
                validationSchema={UserProfileFormSchema(t)}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  updateUser(values);
                }}>
                <FormFields
                  mutationError={error}
                  isSuccess={isSuccess}
                  setDirection={setDirection}
                  userLoading={userLoading}
                  groupIds={data.groupIds}
                  groups={groups}
                  groupsLoading={groupsLoading}
                />
              </Formik>
            )}
          </Box>
        </Grid>
      </Grid>

      <TwoFa open={open} setOpen={setOpen} />

      <Dialog
        open={openDisableDialog}
        onClose={handleCloseDisableDialog}
        aria-labelledby='disable-mfa-title'
        aria-describedby='disable-mfa-description'>
        <DialogTitle id='disable-mfa-title'>
          {t('2fa.disable.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='disable-mfa-dialog-description'>
            {t('2fa.disable.notice')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisableDialog}>{t('cancel')}</Button>
          <Button onClick={handleDisableMfa} autoFocus color='error'>
            {t('disable')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Profile;
