import { LockTwoTone, Menu } from '@mui/icons-material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import SnapGISLogoSVGFull from 'assets/original-full.svg';
import SnapGISLogoSVGIcon from 'assets/original-icon.svg';
import useUIContext from 'context/UIContext';
import { useAuth, useLocalStorage } from 'hooks';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import menuItems from './constants/menu';
import moment from 'moment';

const drawerWidthOpen = 240;
const drawerWidthClosed = 65;
const useStyles: any = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  paper: {
    background: 'rgba(27, 28, 30, 0.75)',
    color: '#fff',
  },
  icon: {
    color: '#fff',
  },
});

const openedMixin = (theme: any, isMobile: boolean) => ({
  width: isMobile ? '100%' : drawerWidthOpen,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: any, isMobile: boolean) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: isMobile ? '100%' : drawerWidthClosed,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }: any) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidthClosed,
    width: `calc(100% - ${drawerWidthClosed}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

// @ts-ignore
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'isMobile',
})<any>(({ theme, open, isMobile }: any) => ({
  width: open ? (isMobile ? '100%' : drawerWidthOpen) : drawerWidthClosed,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, isMobile),
    '& .MuiDrawer-paper': openedMixin(theme, isMobile),
  }),
  ...(!open && {
    ...closedMixin(theme, isMobile),
    '& .MuiDrawer-paper': closedMixin(theme, isMobile),
  }),
}));

enum DRAWER_STATUS {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

const NavigationListItem = ({
  item,
  isOpen,
  className,
  close,
}: {
  item: { name: string; path: string; icon: string } | any;
  isOpen: boolean;
  className: string;
  close: Function;
}) => {
  const { openAiDialog, direction, openHelpCenter, isMobile } = useUIContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const isLink = !!item.path;

  if (isLink) {
    return (
      <ListItem
        dir={direction}
        className={className}
        key={item.name}
        onClick={() => {
          if (isMobile) {
            close();
          }
          navigate(item.path);
        }}
        disablePadding
        sx={{ display: 'block' }}
        style={{
          backgroundColor:
            matchPath(location.pathname, item.path) ||
            (item.path != '/' && location.pathname.includes(item.path))
              ? '#141416'
              : 'transparent',
        }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isOpen ? 'initial' : 'center',
            px: 2.5,
          }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              ...('rtl' === direction
                ? { ml: isOpen ? 3 : 'auto' }
                : { mr: isOpen ? 3 : 'auto' }),
              justifyContent: 'center',
            }}>
            {React.createElement(item.icon, { color: 'secondary' })}
          </ListItemIcon>
          <ListItemText
            color='secondary'
            primary={t(item.name.charAt(0).toUpperCase() + item.name.slice(1))}
            sx={{ opacity: isOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    );
  } else {
    const action = (() => {
      if ('dialog_ai' === item.action) {
        return openAiDialog;
      }
      if ('helpCenter' === item.action) {
        return openHelpCenter;
      }
      return () => {};
    })();
    return (
      <ListItem
        key={item.name}
        onClick={() => action()}
        disablePadding
        sx={{ display: 'block' }}
        style={{ backgroundColor: 'transparent' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: isOpen ? 'initial' : 'center',
            px: 2.5,
          }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              ...('rtl' === direction
                ? { ml: isOpen ? 3 : 'auto' }
                : { mr: isOpen ? 3 : 'auto' }),
              justifyContent: 'center',
            }}>
            {React.createElement(item.icon, { color: 'secondary' })}
          </ListItemIcon>
          <ListItemText
            color='secondary'
            primary={t(item.name.charAt(0).toUpperCase() + item.name.slice(1))}
            sx={{ opacity: isOpen ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    );
  }
};

export default function MiniDrawer() {
  /* @ts-ignore */
  const {
    openLeftSidebar,
    closeLeftSidebar,
    leftSideBarOpen,
    leftSideBarFixed,
    direction,
    setDirection,
    isMobile,
    setIsMobile,
  } = useUIContext();
  const { logout, user } = useAuth() as any;
  const location = useLocation();
  const [isOpen, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedLanguage, _] = useLocalStorage('language', null);
  const { i18n } = useTranslation();
  const touchStartRef = React.useRef(null);
  const touchEndRef = React.useRef(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e: any) => {
    touchEndRef.current = null; // otherwise the swipe is fired even with usual touch events
    touchStartRef.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e: any) => {
    touchEndRef.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStartRef.current || !touchEndRef.current) return;
    const distance = touchStartRef.current - touchEndRef.current;
    const isLeftSwipe = distance > minSwipeDistance;
    if (isLeftSwipe) {
      close();
    }
  };

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 768);
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  React.useEffect(() => {
    console.log(selectedLanguage);
    document.documentElement.lang = selectedLanguage;
    i18n.changeLanguage(selectedLanguage);
    moment.locale(selectedLanguage);
    if (['ar'].includes(selectedLanguage)) {
      setDirection('rtl');
    } else {
      setDirection('ltr');
    }
  }, [selectedLanguage]);

  const onLogout = async () => {
    await logout();
    window.location.reload();
  };

  React.useEffect(() => {
    if (!!leftSideBarOpen) {
      setOpen(true);
      openLeftSidebar(drawerWidthOpen);
    }
  }, [leftSideBarOpen]);

  const open = React.useCallback(() => {
    if (!isOpen) {
      setOpen(true);
      openLeftSidebar(drawerWidthOpen);
    }
  }, [openLeftSidebar, isOpen]);

  const close = React.useCallback(() => {
    if (!leftSideBarFixed && isOpen) {
      setOpen(false);
      closeLeftSidebar(drawerWidthClosed);
    }
  }, [leftSideBarFixed, closeLeftSidebar, isOpen]);

  React.useEffect(() => {
    menuItems.map(item => {
      if (location.pathname === item.path) {
        document.title =
          item.name.charAt(0).toUpperCase() + item.name.slice(1) + ' - SnapGIS';
      }
    });
  }, [location]);

  return (
    <>
      {isMobile && (
        <Box
          sx={{
            width: '100%',
            position: 'fixed',
            top: 0,
            backgroundColor: 'rgba(27, 28, 30, 0.9)',
            height: '64px',
            zIndex: 1001,
          }}>
          <Menu
            sx={{ position: 'absolute', zIndex: 1001, top: 20, left: 10 }}
            onClick={() => (isOpen ? close() : open())}
          />
        </Box>
      )}
      <Box
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        id='navbar'
        sx={{ display: 'flex' }}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(27, 28, 30, 0.75)',
        }}
        onMouseOver={() => (isMobile ? null : open())}>
        <CssBaseline />
        <Drawer
          anchor={'rtl' === direction ? 'right' : 'left'}
          classes={{ paper: classes.paper }}
          variant='permanent'
          open={leftSideBarFixed || isOpen}
          isMobile={isMobile}
          onClose={() => (isMobile ? null : close())}
          style={{
            backgroundColor: 'rgba(27, 28, 30, 0.75)',
            ...('rtl' === direction ? { right: 0 } : { left: 0 }),
            display: isMobile && !isOpen ? 'none' : 'block',
          }}
          SlideProps={{
            appear: false,
            direction: 'rtl' === direction ? 'right' : 'left',
            in: false,
          }}
          id='navbar-drawer'
          className={'main-navigation-drawer'}
          onMouseLeave={() => close()}>
          <DrawerHeader>
            <img
              className={isOpen ? '' : 'hidden'}
              alt='SnapGIS logo'
              src={SnapGISLogoSVGFull}
              style={{
                maxHeight: 30,
                position: 'absolute',
                top: 20,
                left: isMobile ? 40 : 18,
              }}
            />
            <img
              className={isOpen ? 'hidden' : ''}
              alt='SnapGIS logo'
              src={SnapGISLogoSVGIcon}
              style={{ maxHeight: 30, position: 'absolute', top: 20, left: 18 }}
            />
          </DrawerHeader>
          <Divider />
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <List dir={direction}>
              {menuItems
                .filter(
                  (item: any) => (isMobile && !item.hideOnMobile) || !isMobile
                )
                .filter(
                  (item: any) => !item.alignment || item.alignment !== 'bottom'
                )
                .filter((item: any) => {
                  if (!item.userCapabilities) return item;
                  const existsOnUser = item.userCapabilities.filter(
                    (c: any) => !!user[c]
                  );
                  if (item.someUserCapabilities && existsOnUser.length > 0) {
                    return item;
                  }
                  if (existsOnUser.length === item.userCapabilities.length) {
                    return item;
                  }
                })
                .map((item, index) => (
                  <NavigationListItem
                    className={item.className}
                    item={item}
                    key={item.name}
                    isOpen={isOpen}
                    close={close}
                  />
                ))}
            </List>
            <div style={{ justifySelf: 'flex-end' }}>
              <List>
                {menuItems
                  .filter(
                    (item: any) => (isMobile && !item.hideOnMobile) || !isMobile
                  )
                  .filter((item: any) => item.alignment === 'bottom')
                  .map((item, index) => (
                    <NavigationListItem
                      className={item.className}
                      item={item}
                      key={item.name}
                      isOpen={isOpen}
                      close={close}
                    />
                  ))}
              </List>
              <Divider />
              <List>
                <ListItem
                  key={'logout'}
                  onClick={() => onLogout()}
                  disablePadding
                  sx={{ display: 'block' }}
                  style={{
                    backgroundColor: 'transparent',
                  }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: isOpen ? 'initial' : 'center',
                      px: 2.5,
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        ...('rtl' === direction
                          ? { ml: isOpen ? 3 : 'auto' }
                          : { mr: isOpen ? 3 : 'auto' }),
                        justifyContent: 'center',
                      }}>
                      <LockTwoTone color='secondary' />
                    </ListItemIcon>
                    <ListItemText
                      color='secondary'
                      primary={t('Logout')}
                      sx={{ opacity: isOpen ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
          </div>
        </Drawer>
      </Box>
    </>
  );
}
