import {
  Card,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import { useDashboardWidget } from 'hooks';
import moment from 'moment';
import React from 'react';
import {
  Legend,
  Line,
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export const SpeedingLineChart = ({
  assetType,
  widgetType,
  chartUnit,
  showTotal = true,
  startTimestamp,
  endTimestamp,
  groupIds,
  interval = 0,
}: {
  assetType: string;
  widgetType: string;
  chartUnit: string;
  showTotal?: boolean;
  startTimestamp?: Date;
  endTimestamp?: Date;
  groupIds?: [];
  interval?: number;
}) => {
  const DATA_SPEEDING = 'totalOverspeedingTimeSeconds';
  const DATA_LOCAL_SPEEDING = 'totalLocalOverspeedingTimeSeconds';
  const DATA_TOTAL_SPEEDING = 'totalSpeedingTimeSeconds';
  const config: any = {
    [DATA_SPEEDING]: {
      name: 'Overspeeding',
      unit: 'Hours',
      legend: 'Overspeeding hours',
    },
    [DATA_LOCAL_SPEEDING]: {
      name: 'Local Overspeeding',
      unit: 'Hours',
      legend: 'Local Overspeeding hours',
    },
    [DATA_TOTAL_SPEEDING]: {
      name: 'Total speeding',
      unit: 'Hours',
      legend: 'Total Overspeeding hours',
    },
  };

  const { data, isLoading } = useDashboardWidget({
    assetType: assetType,
    widgetType: widgetType,
    startTimestamp: startTimestamp,
    endTimestamp: endTimestamp,
    groupIds: groupIds,
  }) as any;

  const formatValue = (valueInSeconds: number) => {
    const hours = Math.floor(valueInSeconds / 3600);
    const minutes = Math.floor((valueInSeconds % 3600) / 60);
    const seconds = valueInSeconds % 60;
    if (hours === 0 && minutes === 0) {
      return `${seconds} sec`;
    }
    if (hours === 0) {
      return `${minutes} min`;
    }
    return `${hours} h ${minutes} min`;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Card variant='outlined'>
          <CardContent>
            <Typography>{moment(label).format('ddd MMMM Do YYYY')}</Typography>
            <Divider sx={{ my: 1 }} />
            {payload.map((payload: any) => (
              <Typography
                key={payload.name}
                variant='body2'
                sx={{ color: payload.stroke, mb: 0.4 }}>
                <b>{config[payload.name].name}:</b>{' '}
                <span>{formatValue(payload.value)}</span>
              </Typography>
            ))}
          </CardContent>
        </Card>
      );
    }

    return null;
  };

  if (!data || isLoading)
    return <Skeleton variant='rounded' width={'100%'} height={'100%'} />;

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <ResponsiveContainer
        width={`${showTotal ? '80%' : '100%'}`}
        aspect={16 / 3}
        height='100%'>
        <RechartsLineChart
          data={data?.data.map((item: any) => ({
            ...item,
            name: item.date,
            unit: chartUnit,
          }))}>
          <XAxis
            padding={{ left: 10, right: 10 }}
            tick={{ fontSize: 14, textAnchor: 'end', dy: 4 }}
            dataKey='name'
            interval={interval}
            angle={-35}
            // @ts-ignore
            tickFormatter={(value: any, index: number) =>
              value && !!moment(value) ? moment(value).format('DD/MM') : ''
            }
          />
          <YAxis
            yAxisId='left'
            unit={'h'}
            tickFormatter={value => `${Math.floor(value / 3600)}`}
            tick={{
              fontSize: 14,
            }}></YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend
            iconType='plainline'
            wrapperStyle={{ position: 'relative', top: -20 }}
            formatter={(value, entry, index) => {
              return config[value].legend;
            }}
          />
          <Line
            type='monotone'
            dataKey={DATA_SPEEDING}
            stroke='#EB6C67'
            activeDot={{ r: 4 }}
            yAxisId='left'
          />
          <Line
            type='monotone'
            dataKey={DATA_LOCAL_SPEEDING}
            stroke='#ffbf00'
            activeDot={{ r: 4 }}
            yAxisId='left'
          />
          <Line
            type='monotone'
            dataKey={DATA_TOTAL_SPEEDING}
            stroke='#4BA5F8'
            activeDot={{ r: 4 }}
            yAxisId='left'
          />
        </RechartsLineChart>
      </ResponsiveContainer>
      {showTotal && (
        <div style={{ width: '20%', alignSelf: 'center', textAlign: 'center' }}>
          {data?.total && (
            <div style={{ fontSize: '4vh' }}>
              {data.total?.toLocaleString()}
            </div>
          )}
          <div>{chartUnit.charAt(0).toUpperCase() + chartUnit.slice(1)}</div>
        </div>
      )}
    </div>
  );
};

export default SpeedingLineChart;
