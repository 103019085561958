import { IconButton, Tooltip } from '@mui/material';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import BoxedContainer from 'components/BoxedContainer';
import Table from 'components/Table';
import TableToolbar from 'components/TableToolbar';
import TableToolbarActionButton from 'components/TableToolbar/TableToolbarActionButton';
import usePermissions from 'context/PermissionContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const commonColDefOptions: Partial<GridColDef> = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

function getColumns({ t }: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'name',
      headerName: t('Name'),
      ...commonColDefOptions,
    },
    // @ts-ignore
    {
      field: 'type',
      headerName: t('Type'),
      ...commonColDefOptions,
    },
    // @ts-ignore
    {
      field: 'deviceFamily',
      headerName: t('Device Family'),
      ...commonColDefOptions,
    },
    // @ts-ignore
    {
      field: 'assignedDevicesCount',
      headerName: t('# Assigned Devices'),
      ...commonColDefOptions,
    },
    // @ts-ignore
    {
      field: 'updatedAt',
      headerName: t('Last Updated'),
      ...commonColDefOptions,
    },
    // @ts-ignore
    {
      ...commonColDefOptions,
      align: 'center',
      headerName: '',
      minWidth: 90,
      maxWidth: 100,
      renderCell: (r: any) => {
        return (
          <div>
            <Tooltip title={t('Edit Profile')}>
              <IconButton disabled={false} onClick={() => {}}>
                <EditIcon fontSize='small' />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('Delete Profile')}>
              <IconButton disabled={false} onClick={() => {}}>
                <DeleteIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
}

export const DeviceProfiles = () => {
  const { t } = useTranslation();
  const { canWrite } = usePermissions();

  return (
    <BoxedContainer>
      <TableToolbar grid={[10, 2]}>
        <></>
        <>
          {canWrite('ASSET') && (
            <TableToolbarActionButton
              minWidth={140}
              onClick={() => {}}
              translationKey='add_profile'
            />
          )}
        </>
      </TableToolbar>
      <Table
        data={[]}
        isLoading={false}
        columns={getColumns({
          t,
        })}
        onPageChange={() => {}}
        getRowId={() => {}}
      />
    </BoxedContainer>
  );
};

export default DeviceProfiles;
