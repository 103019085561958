import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
} from '@mui/material';
import {
  // eslint-disable-next-line import/named
  GridColDef,
  // eslint-disable-next-line import/named
  GridColumnHeaderParams,
  // eslint-disable-next-line import/named
  GridEventListener,
} from '@mui/x-data-grid';
import Table from 'components/Table';
import InfoHelper from 'components/Tooltip/InfoHelper';
import useUIContext from 'context/UIContext';
import { useAuth } from 'hooks';
import {
  useDriverReport,
  useExportDriversReport,
} from 'hooks/reports/useDriverReport';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDriverScorecardSettings } from '../../hooks/drivers/useDrivers';
import ReportWrapper from '../Reports/ReportWrapper';
import DriverStatusToolbarWithUrlFilters from './DriverStatusFilters';

const commonColDefOptions = {
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

const AllGrades = ['A', 'B', 'C', 'D', 'F'];

const GradeColors = {
  green: '#00b300',
  amber: '#ffbf00',
  red: '#FD6262',
};

function getColumns(
  scoreCardSettings: any,
  t: any,
  isMobile: boolean
): GridColDef[] {
  return [
    //@ts-ignore
    {
      field: 'name',
      headerName: t('Name'),
      ...commonColDefOptions,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.firstName} {params.row.lastName}
          </>
        );
      },
    }, //@ts-ignore
    {
      field: 'groupName',
      headerName: t('Group'),
      ...commonColDefOptions,
      hide: !!isMobile,
      flex: 0.8,
      renderCell: (params: any) => {
        return <>{params.row.groupName}</>;
      },
    }, //@ts-ignore
    {
      field: 'overallScore',
      headerName: t('Driver Score'),
      ...commonColDefOptions,
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      renderCell: (params: any) => {
        if (params.row?.totalDistance === 0) return 'No data';
        if (params.row?.totalDistance === null) return 'No data';
        if (params.row?.score?.overall === null) return 'No data';

        if (!scoreCardSettings) {
          return AllGrades[params.row.score.overall];
        }

        const letter = AllGrades[params.row.score.overall];
        return (
          <b
            style={{
              border: '2px solid',
              borderRadius: '5em',
              width: '1.6em',
              height: '1.6em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderColor:
                GradeColors[
                  scoreCardSettings.grades[letter.toLowerCase()] as
                    | 'green'
                    | 'amber'
                    | 'red'
                ],
            }}>
            {letter}
          </b>
        );
      },
    }, //@ts-ignore
    {
      field: 'abcs',
      headerName: t('ABCS'),
      ...commonColDefOptions,
      hide: !!isMobile,
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          {'ABCS'}
          <InfoHelper
            label={t(`drivers_report.abcs.description`)}
            sx={{ ml: 0.5 }}
          />
        </>
      ),
      renderCell: (params: any) => {
        if (params.row?.totalDistance === 0) return 'No data';
        if (params.row?.totalDistance === null) return 'No data';
        if (params.row?.score?.overall === null) return 'No data';

        if (!scoreCardSettings) {
          return 'No data';
        }

        const letters: { [key: string]: any } = {
          harsh_acceleration: params.row.score.harsh_acceleration?.score,
          harsh_braking: params.row.score.harsh_braking?.score,
          harsh_cornering: params.row.score.harsh_cornering?.score,
          overspeeding: params.row.score.overspeeding?.score,
        };

        return Object.keys(letters)
          .filter(key => letters[key] !== null && letters[key] !== undefined)
          .map(key => {
            return (
              <Tooltip
                key={letters[key]}
                title={t(`drivers_report.abcs.${key}`)}>
                <b
                  style={{
                    marginLeft: '6px',
                    color:
                      GradeColors[
                        scoreCardSettings.grades[letters[key].toLowerCase()] as
                          | 'green'
                          | 'amber'
                          | 'red'
                      ],
                  }}>
                  {letters[key]}
                </b>
              </Tooltip>
            );
          });
      },
    }, //@ts-ignore
    {
      headerName: t('Distance'),
      ...commonColDefOptions,
      flex: 0.65,
      renderCell: (params: any) => {
        return <>{params.row.totalDistance || 0} km</>;
      },
    }, //@ts-ignore
    {
      field: 'totalTrips',
      headerName: t('# of Trips'),
      ...commonColDefOptions,
      hide: !!isMobile,
      flex: 0.5,
      renderCell: (params: any) => {
        return <>{params.row.totalTrips || 0}</>;
      },
    }, //@ts-ignore
    {
      field: 'totalDrivingMinutes',
      headerName: t('Driving Time'),
      ...commonColDefOptions,
      flex: 0.65,
      renderCell: (params: any) => {
        return params.row?.totalDrivingMinutes ? (
          <>
            {(params.row.totalDrivingMinutes / 60).toFixed(2)} {t('hours')}
          </>
        ) : (
          0
        );
      },
    }, //@ts-ignore
    // {
    //   field: 'lastReport',
    //   headerName: t('Last Location'),
    //   ...commonColDefOptions,
    //   flex: 1.5,
    //   renderCell: (params: any) => {
    //     return <>{params.row.lastReport?.formattedAddress}</>;
    //   },
    // }, //@ts-ignore
    {
      field: 'lastReport.asset',
      headerName: t('Last Vehicle'),
      ...commonColDefOptions,
      hide: !!isMobile,
      renderCell: (params: any) => {
        return <>{params.row.lastReport?.asset?.assetName}</>;
      },
    }, //@ts-ignore
    {
      field: 'lastReported',
      headerName: t('Last seen'),
      ...commonColDefOptions,
      hide: !!isMobile,
      flex: 0.6,
      renderCell: (params: any) => {
        return params.row.lastReported
          ? moment(params.row.lastReported).fromNow()
          : 'never';
      },
    }, //@ts-ignore
  ];
}

const DriverReport = (props: any) => {
  const { showFilters = true, boxed = true, driverId } = props;
  const { isMobile } = useUIContext();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(12);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [searchParams] = useComplexSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { data: scoreCardSettings } = useDriverScorecardSettings() as any;
  const { data, isLoading, isRefetching } = useDriverReport({
    page: page,
    pageSize: pageSize,
    sortBy: 'timestamp',
    sortOrder: 'DESC',
    searchTerm,
    filterBy: showFilters
      ? searchParams
      : {
          startTimestamp: moment()
            .startOf('day')
            .subtract(7, 'days')
            .format('YYYY-MM-DD HH:mm:ss'),
          endTimestamp: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          driverId,
        },
  }) as any;
  const { t } = useTranslation();

  useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  const handleEvent: GridEventListener<'rowClick'> = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    navigate(`/drivers/${params.row?.driverUuid}`);
  };

  const ContainerTag: any = boxed ? ReportWrapper : Box;

  const columns = React.useMemo(() => {
    return getColumns(scoreCardSettings, t, isMobile);
  }, []);

  return (
    <div
      style={{
        width: isMobile ? '92vw' : 'calc( 100% - 120px )',
        ...(isMobile && {
          margin: 'auto',
          paddingBottom: '120px',
        }),
      }}>
      <DriverStatusToolbarWithUrlFilters
        assetType={'vehicle'}
        setSearchTerm={setSearchTerm}
        exportComponent={isRefetching ? Fragment : ExportDriversReport}
        gridSx={isMobile ? { display: 'none' } : {}}
      />
      <ContainerTag>
        <Table
          data={data?.drivers}
          isLoading={isLoading}
          getRowId={(row: any) => row.driverId}
          columns={columns}
          disableColumnFilter={true}
          disableColumnMenu={true}
          onRowClick={handleEvent}
          pageSize={pageSize}
          onPageChange={(newPage: number) => setPage(newPage + 1)}
          rowCount={rowCountState}
        />
      </ContainerTag>
    </div>
  );
};

const ExportDriversReport = ({ filters }: any) => {
  const { t } = useTranslation();
  const { setFlashMessage } = useUIContext();
  const { user } = useAuth() as any;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [canExport, setCanExport] = React.useState<boolean>(true);
  const [prevSearchParams, setPrevSearchParams] = React.useState<any>(null);
  const [searchParams] = useComplexSearchParams();

  React.useEffect(() => {
    if (JSON.stringify(searchParams) !== JSON.stringify(prevSearchParams)) {
      setCanExport(true);
      setPrevSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {
    data: exportData,
    mutate: exportReport,
    error: exportError,
    reset: exportReset,
    isLoading: isExporting,
    isSuccess: exportSuccess,
  } = useExportDriversReport();

  const handleExport = (fileType: any) => {
    handleClose();
    setCanExport(false);
    exportReport({ ...filters, fileType });
  };

  React.useEffect(() => {
    if (exportData && !exportData.exportUrl) {
      setFlashMessage({ message: t(`reports.${exportData.message}`) });
    }
  }, [exportData]);

  return (
    <>
      <Tooltip
        arrow={true}
        title={
          <div style={{ textAlign: 'center' }}>
            {user?.hasAISVessels ? (
              <>
                {t('reports.export_report')}
                <br />
                {t('reports.export_report_ais_note')}
              </>
            ) : (
              t('reports.export_report')
            )}
          </div>
        }>
        <IconButton disabled={isExporting || !canExport} onClick={handleClick}>
          {isExporting ? (
            <CircularProgress size={24} sx={{ mr: 0.25 }} />
          ) : (
            <DownloadIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('excel')}>
              <ListItemText
                primary='.xlsx'
                primaryTypographyProps={{ fontSize: '.85rem' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleExport('pdf')}>
              <ListItemText
                primaryTypographyProps={{ fontSize: '.85rem' }}
                primary='.pdf'
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {exportSuccess && exportData && exportData.reportUrl && (
        <iframe
          width='1pxre'
          height='1px'
          style={{ visibility: 'hidden', position: 'absolute' }}
          referrerPolicy='origin'
          src={exportData.reportUrl}></iframe>
      )}
    </>
  );
};

export default DriverReport;
