import {
  useQuery,
  UseQueryResult
} from 'react-query';
import { apiClient } from 'services/axios';

export function useOrganization(): UseQueryResult {
  return useQuery(['organization'], () =>
    apiClient.get(`organizations`)
  );
}