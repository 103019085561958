import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import {
  CellTowerOutlined,
  Delete,
  PersonOutline,
  QrCode,
  SatelliteAltOutlined,
  SimCardOutlined,
  SmartphoneOutlined,
  WavesOutlined,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import { Asset } from 'common-web/types/asset/Asset';
import { getDeviceFullName } from 'common-web/utils';
import BoxedContainer from 'components/BoxedContainer';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import Table from 'components/Table';
import { ToolTipClipboard } from 'components/Tooltip';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { useAssets, useDeleteAsset } from 'hooks/assets/useAssets';
import useComplexSearchParams from 'hooks/useComplexSearchParams';
import { ReactCountryFlag } from 'react-country-flag';
import { useNavigate } from 'react-router-dom';
import AssetsToolbar from './AssetsToolbar';

const getCountryISO2 = require('country-iso-3-to-2');
const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

const commonColDefOptions: Partial<GridColDef> = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
};

function getColumns({
  getDeviceFullName,
  handleEditAsset,
  handleDeleteAsset,
  isDeleting,
  canWrite,
  t,
  navigate,
}: any): GridColDef[] {
  return [
    // @ts-ignore
    {
      field: 'name',
      headerName: t('Name'),
      ...commonColDefOptions,
    }, // @ts-ignore
    {
      field: 'assetType',
      headerName: t('Asset Type'),
      ...commonColDefOptions,
      disableColumnMenu: false,
      filterable: true,
      flex: 0.75,
      valueFormatter: ({ value }) => t(`asset.types.${value}`),
    }, // @ts-ignore
    {
      field: 'group',
      headerName: t('Group'),
      ...commonColDefOptions,
      disableColumnMenu: false,
      filterable: true,
      valueGetter: ({ row }) => {
        return row.group?.name;
      },
    }, // @ts-ignore
    {
      field: 'deviceType',
      headerName: t('Device Type'),
      ...commonColDefOptions,
      disableColumnMenu: false,
      filterable: true,
      valueGetter: ({ row }) => {
        return Array.from(
          new Set(
            row.assetDevices
              ?.map((item: any) => getDeviceFullName(item?.deviceType))
              .join(', ')
              .split(', ')
          )
        ).join(', ');
      },
    }, // @ts-ignore
    {
      field: 'countryCode',
      headerName: t('Last Reported From'),
      ...commonColDefOptions,
      renderCell: (r: any) => {
        return r?.row?.assetRecent?.formattedAddress ? (
          <Tooltip arrow={true} title={r?.row?.assetRecent?.formattedAddress}>
            <Box>
              <ReactCountryFlag
                countryCode={getCountryISO2(r?.row?.assetRecent?.countryCode)}
              />
              <Box sx={{ ml: 1, display: 'inline' }}>
                {regionNamesInEnglish.of(
                  getCountryISO2(r?.row?.assetRecent?.countryCode)
                )}
              </Box>
            </Box>
          </Tooltip>
        ) : (
          ''
        );
      },
    }, // @ts-ignore
    {
      field: 'deviceIdentifiers',
      headerName: '',
      valueGetter: ({ row }) => {
        return row.assetDevices?.map((as: any) => as.assetDeviceIdentifiers)[0];
      },
      ...commonColDefOptions,
      align: 'center',
      minWidth: 60,
      maxWidth: 80,
      filterable: false,
      sortable: false,
      renderCell: (r: any) => {
        return (
          <div>
            {r.value
              ?.sort((a: any, b: any) => (a.type > b.type ? 1 : -1))
              .map((d: any) => {
                return (
                  <>
                    {'phone_number' === d.type && (
                      <ToolTipClipboard
                        title={<>{`${t('Number')}: ${d.identifier}`}</>}
                        clipboard={d.identifier}>
                        <SmartphoneOutlined
                          style={{
                            color: 'rgba(255,255,255,0.75)',
                            cursor: 'pointer',
                          }}
                        />
                      </ToolTipClipboard>
                    )}
                    {'imei' === d.type && (
                      <ToolTipClipboard
                        title={<>{`${t('IMEI')}: ${d.identifier}`}</>}
                        clipboard={d.identifier}>
                        <CellTowerOutlined
                          style={{
                            color: 'rgba(255,255,255,0.75)',
                            cursor: 'pointer',
                          }}
                        />
                      </ToolTipClipboard>
                    )}
                    {'twilio_sid' === d.type && (
                      <ToolTipClipboard
                        title={<>{`${t('Twilio SID')}: ${d.identifier}`}</>}
                        clipboard={d.identifier}>
                        <SimCardOutlined
                          style={{
                            color: 'rgba(255,255,255,0.75)',
                            cursor: 'pointer',
                          }}
                        />
                      </ToolTipClipboard>
                    )}
                    {'mmsi' === d.type && (
                      <ToolTipClipboard
                        title={<>{`${t('MMSI')}: ${d.identifier}`}</>}
                        clipboard={d.identifier}>
                        <WavesOutlined
                          style={{
                            color: 'rgba(255,255,255,0.75)',
                            cursor: 'pointer',
                          }}
                        />
                      </ToolTipClipboard>
                    )}
                    {'terminal_id' === d.type && (
                      <ToolTipClipboard
                        title={<>{`${t('Terminal ID')}: ${d.identifier}`}</>}
                        clipboard={d.identifier}>
                        <SatelliteAltOutlined
                          style={{
                            color: 'rgba(255,255,255,0.75)',
                            cursor: 'pointer',
                          }}
                        />
                      </ToolTipClipboard>
                    )}
                    {'deveui' === d.type && (
                      <ToolTipClipboard
                        title={<>{`${t('DevEUI')}: ${d.identifier}`}</>}
                        clipboard={d.identifier}>
                        <CellTowerOutlined
                          style={{
                            color: 'rgba(255,255,255,0.75)',
                            cursor: 'pointer',
                          }}
                        />
                      </ToolTipClipboard>
                    )}
                    {'device_serial_number' === d.type && (
                      <ToolTipClipboard
                        title={
                          <>{`${t('device_serial_number')}: ${d.identifier}`}</>
                        }
                        clipboard={d.identifier}>
                        <QrCode
                          style={{
                            color: 'rgba(255,255,255,0.75)',
                            cursor: 'pointer',
                          }}
                        />
                      </ToolTipClipboard>
                    )}
                  </>
                );
              })}
          </div>
        );
      },
    }, //@ts-ignore
    {
      field: 'defaultDriver',
      headerName: '',
      ...commonColDefOptions,
      flex: 0.4,
      renderCell: (r: any) => {
        return (
          <>
            {r?.row?.hasIgnitionLockout && (
              <Tooltip arrow={true} title={t(`Fitted with ignition lockout`)}>
                <LockIcon
                  style={{
                    fontSize: '1.5em',
                    color: 'rgba(255,255,255,0.75)',
                  }}
                />
              </Tooltip>
            )}
            {r?.row?.defaultDriver && r?.row?.defaultDriver?.firstName ? (
              <Tooltip
                arrow={true}
                title={`${r?.row?.defaultDriver?.firstName} ${
                  r?.row?.defaultDriver?.lastName || ''
                }`}>
                <PersonOutline
                  onClick={() =>
                    navigate(`/drivers/${r?.row?.defaultDriver?.driverUuid}`)
                  }
                  style={{
                    color: 'rgba(255,255,255,0.75)',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
          </>
        );
      },
    }, // @ts-ignore
    ...(canWrite('ASSET')
      ? [
          {
            ...commonColDefOptions,
            align: 'center',
            headerName: '',
            minWidth: 90,
            maxWidth: 100,
            renderCell: (r: any) => {
              return (
                <div>
                  <Tooltip title={t('Edit asset')}>
                    <IconButton
                      disabled={isDeleting}
                      onClick={() => handleEditAsset(r?.row?.assetId)}>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Delete asset')}>
                    <IconButton
                      disabled={isDeleting}
                      onClick={() => handleDeleteAsset(r?.row)}>
                      <Delete fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            },
          },
        ]
      : []),
  ];
}

const Assets = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchParams] = useComplexSearchParams();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(12);
  const { data, isLoading } = useAssets({
    searchTerm,
    pageSize,
    page,
    filterBy: searchParams,
  }) as any;
  const { mutate, error, isSuccess, isLoading: isDeleting } = useDeleteAsset();
  const { openRightSidebar } = useUIContext();
  const { setActive, reset } = useActionMenuContext();
  const { canWrite } = usePermissions();
  const [deleteAsset, setDeleteAsset] = useState<Asset | null>(null);
  const [rowCountState, setRowCountState] = React.useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    return () => reset();
  }, []);

  React.useEffect(() => {
    if (isSuccess) {
      setDeleteAsset(null);
    }
  }, [isSuccess]);

  const handleAddAsset = () => {
    setActive(ActionMenuModuleTypes.AddAsset);
    openRightSidebar();
  };

  const handleEditAsset = (id: number) => {
    setActive(ActionMenuModuleTypes.EditAsset, { assetId: id });
    openRightSidebar();
  };

  const handleDeleteAsset = (asset: Asset) => {
    setDeleteAsset(asset);
  };

  React.useEffect(() => {
    setRowCountState(data?.numRecords ?? 0);
  }, [data?.numRecords, setRowCountState]);

  const columns = React.useMemo(() => {
    return getColumns({
      getDeviceFullName,
      handleEditAsset,
      handleDeleteAsset,
      isDeleting,
      canWrite,
      t,
      navigate,
    });
  }, [
    getDeviceFullName,
    handleEditAsset,
    handleDeleteAsset,
    isDeleting,
    canWrite,
    t,
    navigate,
  ]);

  return (
    <BoxedContainer>
      <AssetsToolbar
        handleAddAsset={handleAddAsset}
        setSearchTerm={setSearchTerm}
      />
      <Table
        data={data?.assets}
        isLoading={isLoading}
        getRowId={(row: any) => row.assetId}
        columns={columns}
        disableColumnFilter={true}
        disableColumnMenu={true}
        pageSize={pageSize}
        onPageChange={(newPage: number) => setPage(newPage + 1)}
        rowCount={rowCountState}
      />
      <ConfirmDeleteDialog
        open={!!deleteAsset}
        onCancel={() => {
          setDeleteAsset(null);
        }}
        onConfirm={() => {
          if (deleteAsset) mutate({ id: deleteAsset?.assetId });
        }}
        title={t('Confirm to delete asset')}
        content={
          <>
            {t('Are you sure you want to delete')} <b>{deleteAsset?.name}</b>?
            <br />
            <strong style={{ color: '#f44336' }}>{t('NOTE:')}</strong>{' '}
            {t('This action cannot be undone!')}
          </>
        }
      />
    </BoxedContainer>
  );
};

export default Assets;
